import { useEffect, useState } from "react";
import { CircaPackage, GraphImage, GraphImageFor, GraphType, Project } from "../../dataTypes/generated";
import { PhaseDiagram } from "../../projects/competence/PhaseDiagram";
import { ProjectSelect } from "../../../leading/dialogs/ProjectSelect";
import { CircalindApi } from "../../circalindApi";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "./PhaseDiagrams.scss"
import { useCurrentPng } from "recharts-to-png";

export const PhaseDiagrams = ({ projectId, chooseProject, graphForChoices, userId, authorId }: { projectId?: number, chooseProject: boolean, graphForChoices?: Array<GraphImageFor>, userId: number, authorId: number }) => {
    const [currentGraph, setCurrentGraph] = useState<GraphImage>();
    const [allGraphs, setAllGraphs] = useState<Array<GraphImage>>([]);
    const [currentGraphType, setCurrentGraphType] = useState<GraphType>("RADAR");
    const [currentGraphFor, setCurrentGraphFor] = useState<GraphImageFor>(graphForChoices && graphForChoices.length > 0 ? graphForChoices[0] : "TOOLBOX");
    const [selectedProject, setSelectedProject] = useState<number | undefined>(projectId);
    const [phase, setPhase] = useState<number>(1);
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [edit, setEdit] = useState<Record<number, boolean>>({});
    const [license, setLicense] = useState<CircaPackage | null>(null);
    const [getPng, { ref }] = useCurrentPng();

    useEffect(() => {
        const loadProjects = async () => {
            const ps = (await CircalindApi.getMyProjects()).map(p => p.project);
            const project = ps.find(p => p.id === projectId);
            setProjects(ps);

            if (project) {
                setPhase(project.phase !== 0 ? project.phase : 1);
            }
        };

        const loadLicense = async () => {
            setLicense(await CircalindApi.getLicense());
        }

        loadLicense();
        loadProjects();
    }, [projectId]);

    const loadGraphs = async () => {
        setAllGraphs((await CircalindApi.listGraphImages(userId, selectedProject ? selectedProject : null, phase, currentGraphFor)).filter(gi => gi.authorId === authorId));
    };

    useEffect(() => {
        const internalLoadGraphs = async () => {
            setAllGraphs((await CircalindApi.listGraphImages(userId, selectedProject ? selectedProject : null, phase, currentGraphFor)).filter(gi => gi.authorId === authorId));
        };

        internalLoadGraphs();
    }, [authorId, currentGraphFor, phase, selectedProject, userId]);



    const createGraph = () => {
        if (userId) {
            const emptyGraph: GraphImage = {
                id: -1,
                userId: userId,
                projectId: selectedProject ? selectedProject : null,
                authorId: authorId,
                phase: phase,
                name: "",
                graphType: currentGraphType,
                graphFor: currentGraphFor,
                json: "",
                base64: ""
            };

            setCurrentGraph(emptyGraph);
        }
    };

    const typeOptions = [
        { label: "Spinnen-Graph", value: "RADAR" as GraphType },
        { label: "Linien-Graph", value: "LINE" as GraphType },
        { label: "Balken-Graph", value: "BAR" as GraphType },
        { label: "Pfeil-Graph", value: "ARROWS" as GraphType },
        { label: "Farb-Tafel", value: "SQARES" as GraphType }
    ];

    const forOptions = [
        { label: "Toolbox", value: "TOOLBOX" as GraphImageFor },
        { label: "Persönliche Kompetenz", value: "PERSONAL_COMPETENCE" as GraphImageFor },
        { label: "Projekt-Entwicklung (SOLL/IST)", value: "PROJECT_PHASE_COMPETENCE" as GraphImageFor },
        { label: "Wechselwirkungs-Check", value: "POST_PHASE_INTERACTION" as GraphImageFor },
        { label: "Wirksamkeits-Hebel-Check", value: "POST_PHASE_EFFECTIVITY" as GraphImageFor },
    ].filter(o => graphForChoices !== undefined ? graphForChoices.includes(o.value) : true);

    const phaseOptions = (license && license.type !== "UK1" ? [1, 2, 3, 4, 5] : []).map(p => {
        return {
            label: `Phase ${p}`, value: p
        }
    });

    phaseOptions.push({ label: "Phasenübergreifend", value: -1 });

    const saveCurrentGraph = async () => {
        if (currentGraph) {
            const png = currentGraph.graphType === "SQARES" ? `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(ref.current.innerHTML)))}` : await getPng();

            if (png) {
                const toBeSavedGraph: GraphImage = { ...currentGraph, base64: png };
                await CircalindApi.saveGraphImage(toBeSavedGraph);
                setCurrentGraph(undefined);
                await loadGraphs();
            }
        }
    };

    const renderExistingGraphs = () => {
        return <div className="">
            {allGraphs.map(g => {
                return <SingleEditableDiagram allGraphs={allGraphs} setAllGraphs={setAllGraphs} g={g} loadGraphs={loadGraphs} edit={edit} setEdit={setEdit} />
            })}
        </div>
    };

    const dependsOnPhase = currentGraphFor === "PROJECT_PHASE_COMPETENCE";

    return <div className="phase-diagrams">
        <div className="graph-config">
            {chooseProject && <ProjectSelect valueProjectId={selectedProject} projects={projects} onSelect={p => setSelectedProject(p?.id)} />}
            {dependsOnPhase && <Select value={phaseOptions.find(po => po.value === phase)} options={phaseOptions} onChange={o => setPhase(o!.value)} />}
            <Select value={forOptions.find(fo => fo.value === currentGraphFor)} options={forOptions} onChange={o => setCurrentGraphFor(o!.value)} />
            <Select value={typeOptions.find(to => to.value === currentGraphType)} options={typeOptions} onChange={o => setCurrentGraphType(o!.value)} />
            <button onClick={createGraph}>Neues Diagramm</button>
        </div>
        <div className="graphs">
            {currentGraph && <>
                <PhaseDiagram innerRef={ref} showTitle={true} edit={true} value={currentGraph} updateGraphImage={g => setCurrentGraph(g)} />
                <button disabled={!currentGraph.name} onClick={() => saveCurrentGraph()}>Speichern</button>
            </>}
            {renderExistingGraphs()}
        </div>
    </div>
};

const SingleEditableDiagram = ({ allGraphs, setAllGraphs, g, loadGraphs, edit, setEdit }: { allGraphs: Array<GraphImage>, setAllGraphs: (ag: Array<GraphImage>) => void, g: GraphImage, loadGraphs: () => void, edit: Record<number, boolean>, setEdit: (m: Record<number, boolean>) => void }) => {
    const [getPng, { ref }] = useCurrentPng();

    const deleteGraph = async () => {
        await CircalindApi.deleteGraphImage(g);
        await loadGraphs();
    };

    const editGraph = async () => {
        setEdit({ ...edit, [g.id]: !!!edit[g.id] })
    };

    const updateGraph = (g: GraphImage) => {
        const copy = [...allGraphs];
        const index = copy.findIndex(cg => cg.id === g.id);

        if (index !== -1) {
            copy[index] = g;
            setAllGraphs(copy);
        }
    };

    const saveGraph = async () => {
        const png = g.graphType === "SQARES" ? `data:image/svg+xml;base64,${btoa(ref.current.innerHTML)}` : await getPng();

        if (png) {
            const toBeSavedGraph: GraphImage = { ...g, base64: png };
            await CircalindApi.saveGraphImage(toBeSavedGraph);
            setEdit({ ...edit, [g.id]: false });
        }
    };

    return <div key={g.id}>
        <PhaseDiagram innerRef={ref} showTitle={true} edit={!!edit[g.id]} value={g} updateGraphImage={!!edit[g.id] ? updateGraph : () => { }} />
        <div className="buttons">
            <button onClick={deleteGraph}><FontAwesomeIcon icon={faTrashAlt} /></button>
            <button onClick={editGraph}>{!!edit[g.id] ? "Abbrechen" : <FontAwesomeIcon icon={faPen} />}</button>
            {!!edit[g.id] && <button onClick={saveGraph}>Speichern</button>}
        </div>
    </div>
};