import { useEffect, useState } from "react";
import { Project, ProjectFamily } from "../../../dataTypes/generated";
import { Dialog } from "../../../basicComponents/dialogs/Dialog";
import { getCurrentUserId } from "../../../../utils/Auth";
import { CircalindApi } from "../../../circalindApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPen, faTrashAlt, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { ProjectSelect } from "../../../../leading/dialogs/ProjectSelect";

import syncImage from "../../../../resources/circa/synchro.png";

import "./ProjectFamilyOverview.scss"
import { useNavigate } from "react-router";
import { getTodoColor } from "../../../practical/transfer/NewTodo";

const createEmptyFamily = (owner: number): ProjectFamily => {
    return {
        id: -1,
        projects: [],
        owner: owner,
        name: "",
        currentDescription: "",
        middleDescription: "",
        longDescription: "",
        leadProject: null
    };
};

export const ProjectFamilyOverview = () => {
    const userId = getCurrentUserId("token-circalind");
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [families, setFamilies] = useState<Array<ProjectFamily>>([]);
    const [family, setFamily] = useState<ProjectFamily>(createEmptyFamily(userId === null ? -1 : userId));
    const [showFamilyDialog, setShowFamilyDialog] = useState<boolean>(false);
    const [showProjectDialog, setShowProjectDialog] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<number>();

    const navigate = useNavigate();

    const saveFamily = async () => {
        if (family.id === -1) {
            await CircalindApi.addProjectFamily(family);
        } else {
            await CircalindApi.updateProjectFamily(family);
        }
    }

    const loadProjects = async () => {
        setProjects(await CircalindApi.getProjects());
    };


    const loadFamilies = async () => {
        setFamilies(await CircalindApi.getMyProjectFamilies());
    };

    useEffect(() => {
        loadFamilies();
        loadProjects();
    }, []);

    const renderProject = (pid: number, family: ProjectFamily) => {
        const project = projects.find(p => p.id === pid);

        if (project) {
            return <div className="project card" key={pid}>
                {<span className="project-name">
                    {renderStatusCircle(getTodoColor(project.status))} {project.name} {family.leadProject === pid ? <strong className="main-project">(Haupt-Projekt)</strong> : ""}
                </span>}
                <FontAwesomeIcon className="icon" onClick={async () => { setProjectId(pid); setFamily(family); await unlinkProject() }} icon={faUnlink} />
            </div>
        }
    };

    const renderFamily = (family: ProjectFamily) => {
        return <div className="family card" key={family.id}>
            <h3 onClick={() => navigate({ pathname: "../synchro", search: "?family=" + family.id })}>{family.name}</h3>
            <div className="projects">
                {family.projects.map(p => renderProject(p, family))}
            </div>
            <div className="actions">
                <FontAwesomeIcon className="icon" onClick={async () => { await CircalindApi.deleteProjectFamily(family.id); await loadFamilies(); }} icon={faTrashAlt} />
                <FontAwesomeIcon className="icon" onClick={async () => { setFamily(family); setShowFamilyDialog(true) }} icon={faPen} />
                <FontAwesomeIcon className="icon" onClick={async () => { setFamily(family); setShowProjectDialog(true) }} icon={faLink} />
            </div>
        </div>
    };

    const linkProject = async () => {
        if (projectId !== undefined) {
            const otherFamily = families.find(f => f.id !== family.id && f.projects.includes(projectId));

            if (otherFamily) {
                return;
            }

            await CircalindApi.linkToProjectFamily({ projectId: projectId, familyId: family.id });
            loadFamilies();
        }
    };

    const unlinkProject = async () => {
        if (projectId !== undefined) {
            await CircalindApi.unlinkToProjectFamily({ projectId: projectId, familyId: family.id });
            loadFamilies();
        }
    };

    return <div className="project-family-overview">
        <div className="button-wrapper">
            <img src={syncImage} alt="" />
            <h2>Projekt-Cluster</h2>
            <button onClick={() => { setFamily(createEmptyFamily(userId === null ? -1 : userId)); setShowFamilyDialog(true) }}>Cluster hinzufügen</button>
        </div>
        <div className="families">
            {families.map(renderFamily)}
        </div>
        <Dialog className={""} component={<div className="family-dialog">
            <label>Cluster-Titel: </label><input onChange={(e) => setFamily({ ...family, name: e.target.value })} value={family.name} type="text" />
            <label>Haupt-Projekt: </label>
            <ProjectSelect projects={projects.filter(p => family.projects.includes(p.id))} valueProjectId={family.leadProject ? family.leadProject : undefined} onSelect={p => setFamily({ ...family, leadProject: p ? p.id : null })} />
            <label>Beschreibung Aktuell: </label><input onChange={(e) => setFamily({ ...family, currentDescription: e.target.value })} value={family.currentDescription} type="text" />
            <label>Beschreibung Mittelfristig: </label><input onChange={(e) => setFamily({ ...family, middleDescription: e.target.value })} value={family.middleDescription} type="text" />
            <label>Beschreibung Langfristig: </label><input onChange={(e) => setFamily({ ...family, longDescription: e.target.value })} value={family.longDescription} type="text" />
            <button onClick={async () => { await saveFamily(); await loadFamilies(); setShowFamilyDialog(false) }}>Speichern</button>
        </div>} show={showFamilyDialog} toogle={() => setShowFamilyDialog(false)} />
        <Dialog className={""} component={<div className="project-dialog"><ProjectSelect projects={projects} valueProjectId={projectId} onSelect={p => setProjectId(p?.id || undefined)} /><button onClick={async () => { await linkProject(); setShowProjectDialog(false) }}>Speichern</button></div>} show={showProjectDialog} toogle={() => setShowProjectDialog(false)} />
    </div>
};

export const renderStatusCircle = (fill: string = "#000") => {
    return <svg className="status-circle" height="14" width="14">
        <circle
            cx="7"
            cy="7"
            r="7"
            fill={fill}
        />
    </svg>
};