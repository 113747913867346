
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <App />
  );
} else {
  <h2>ERROR</h2>
}

