import { Fragment, ReactElement, useEffect, useMemo, useState } from "react";
import { CircalindApi } from "../../circalind/circalindApi";
import { Area, AreaAndRoleInfo, InternalGroup, Permissions, Project, RoleType, SubArea, User } from "../../circalind/dataTypes/generated";
import { sendOlert } from "../../olert/Olert";
import { areaImages } from "../areas";
import { UserSelect } from "../dialogs/UserSelect";
import { getCurrentUserId, getUserRoles } from "../../utils/Auth";
import { ProjectSelect } from "../dialogs/ProjectSelect";
import Select from 'react-select';

import './leading.scss'
import { RoleCircle } from "../role";

export interface PermissionsProps {
    users: User[];
    projects: Project[];
    groups: InternalGroup[];
    deciderOnly: boolean
}

export enum PermissionMode {
    LEADER, DECIDER_PROJECT, DECIDER_COMPETENCE
}

export const PermissionsCard = (props: PermissionsProps): ReactElement => {
    const { users, deciderOnly } = props;
    const [projectId, setProjectId] = useState<number>(-1);
    const [selectedMode, setSelectedMode] = useState<PermissionMode>(PermissionMode.LEADER);
    const [targetUserId, setTargetUserId] = useState<number>(-1);
    const [permissions, setPermissions] = useState<Permissions[]>([])
    const [assigneeId, setAssigneeId] = useState<number>(-1);
    const [internalGroupId,] = useState<number>(-1);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>();
    const [subAreas, setSubAreas] = useState<Array<SubArea>>([]);
    const [annotations, setAnnotations] = useState<Record<number, ReactElement>>({});
    const assignee = assigneeId === -1 ? undefined : users.find(u => u.id === assigneeId);
    const assignerId = getCurrentUserId("token-circalind");
    const assigner = users.find(u => u.id === assignerId);

    const projectSpecific = projectId !== -1;

    useEffect(() => {
        const newAnnotations: Record<number, ReactElement> = {};

        if (projectId === -1) {
            for (let user of users) {
                newAnnotations[user.id] = <span>{user.roles.map(r => <RoleCircle key={r} role={r} />)}</span>;
            }
        } else {
            const project = props.projects.find(p => p.id === projectId);
            if (project) {
                const roles: Record<number, Array<RoleType>> = {};

                for (let member of project.members) {
                    if (roles[member.user.id]) {
                        roles[member.user.id].push(member.role);
                    } else {
                        roles[member.user.id] = [member.role];
                    }
                }

                for (let userId of Object.keys(roles)) {
                    newAnnotations[+userId] = <span>{roles[+userId].map(r => <RoleCircle key={r} role={r} />)}</span>;
                }
            }
        }

        setAnnotations(newAnnotations);
    }, [users, areaAndRoleInfo, projectId, props.projects]);

    const target: Target = useMemo(() => {
        return projectId === -1
            ? { targetUserId: targetUserId === -1 ? null : targetUserId, targetProjectId: null, targetInternalGroupId: null }
            : { targetUserId: null, targetProjectId: projectId, targetInternalGroupId: null };
    }, [targetUserId, projectId]);

    useEffect(() => {
        const getData = async () => {
            const areaInfo = await CircalindApi.getAreaAndRoleInfo();
            setAreaAndRoleInfo(areaInfo);
            const subAreas = await CircalindApi.getSubAreas({ groupId: -1, area: null, ...target });
            setSubAreas(subAreas);
        }
        getData();
    }, [target])

    useEffect(() => {
        const getData = async () => {
            const project = props.projects.find(p => p.id === projectId) || null;

            if (assignee && (!deciderOnly || assigner)) {
                const res = await loadUserPermissions();
                const toShow = addEmpty(subAreas, res, projectId, internalGroupId, assignee.id, targetUserId, projectSpecific);
                const filtered = filterPermissions(toShow, assignee.id, target);
                const filteredByRole = deciderOnly ?
                    filterPermissionsByRoleForDecider(filtered, subAreas, assignee, assigner!, targetUserId, project) :
                    filterPermissionsByRole(filtered, subAreas, assignee, targetUserId, project);
                const sorted = filteredByRole.sort((a, b) => {
                    if (lookupName(subAreas, a.subArea) < lookupName(subAreas, b.subArea)) {
                        return -1;
                    }
                    if (lookupName(subAreas, a.subArea) > lookupName(subAreas, b.subArea)) {
                        return 1;
                    }
                    return 0;
                });
                setPermissions(sorted);
            } else {
                setPermissions([]);
            }
        }

        getData();
    }, [
        props.projects,
        assignee,
        assigner,
        deciderOnly,
        target,
        projectSpecific,
        internalGroupId,
        targetUserId,
        subAreas,
        projectId]
    );



    const updatePermission = async (permission: Permissions) => {
        const res = await CircalindApi.setPermission(permission);
        const project = props.projects.find(p => p.id === projectId) || null;

        if (res === true && assignee && (!deciderOnly || assigner)) {
            const perm = await loadUserPermissions();
            const toShow = addEmpty(subAreas, perm, projectId, internalGroupId, assignee.id, targetUserId, projectSpecific);
            const filtered = filterPermissions(toShow, assignee.id, target);
            const filteredByRole = deciderOnly ?
                filterPermissionsByRoleForDecider(filtered, subAreas, assignee, assigner!, targetUserId, project) :
                filterPermissionsByRole(filtered, subAreas, assignee, targetUserId, project);
            const sorted = filteredByRole.sort((a, b) => {
                if (lookupName(subAreas, a.subArea) < lookupName(subAreas, b.subArea)) {
                    return -1;
                }
                if (lookupName(subAreas, a.subArea) > lookupName(subAreas, b.subArea)) {
                    return 1;
                }
                return 0;
            });
            setPermissions(sorted);
            sendOlert("Erfolgreich", "Berechtigung gespeichert", "Success");
        } else {
            sendOlert("Fehler", "Berechtigung konnte nicht gespeichert werden", "Error")
        }
    }

    const toggleEdit = async (id: number) => {
        if (permissions) {
            let newP = [...permissions];
            const p = permissions.findIndex(p => p.subArea === id);
            if (p >= -1) {
                newP[p].edit = !newP[p].edit;
                await updatePermission(newP[p]);
            }
        }
    }

    const renderPermissionCheckboxes = () => {
        if (permissions !== null) {
            return permissions.map((r, i) => {
                const subArea = subAreas.find(sa => sa.id === r.subArea);

                if (subArea) {
                    const area = subArea?.area
                    const title = area && areaAndRoleInfo ? areaAndRoleInfo.areaTitles[area] : area;
                    const subtitle = subArea?.name;
                    const combinedTitle = [title, subtitle].filter(t => t).join(" - ");

                    return <Fragment key={i}>
                        <span className="permission">
                            {area && areaImages[area] ?
                                <img src={areaImages[area]} alt={combinedTitle} />
                                : undefined
                            }
                            <span>{combinedTitle}</span>
                        </span>
                        <input type={"checkbox"} checked={r.edit} onChange={() => toggleEdit(subArea.id)} />
                    </Fragment>
                } else {
                    return undefined;
                }
            });
        }
    }

    const renderDependentSelection = () => {
        const project = props.projects.find(p => p.id === projectId);

        const modeOptions = [{
            label: "Führung und Zusammenarbeit",
            value: PermissionMode.LEADER
        }, {
            label: "Entscheiderwelt: Projekte",
            value: PermissionMode.DECIDER_PROJECT
        }, {
            label: "Entscheiderwelt: Kompetenzen und Talente",
            value: PermissionMode.DECIDER_COMPETENCE
        }
        ];

        const renderDepedentOnMode = () => {
            switch (selectedMode) {
                case PermissionMode.LEADER:
                    return <div>
                        <label>(Co-)Koordinator wählen</label>
                        <UserSelect valueUserId={targetUserId === -1 ? undefined : targetUserId} users={users.filter(u => u.roles.includes("COORDINATOR") || u.roles.includes("CO_COORDINATOR") || u.roles.includes("IT_COORDINATOR") || u.roles.includes("QM_COORDINATOR"))} useName={true} onSelect={u => setTargetUserId(u ? u.id : -1)} />
                    </div>
                case PermissionMode.DECIDER_PROJECT:
                    return <div>
                        <label>Entscheider-Projekt wählen</label>
                        <ProjectSelect projects={props.projects} onSelect={(e) => setProjectId(e ? e.id : -1)} valueProjectId={projectId} />
                        {project && <span className="user-roles">Rollen: {project.members.filter(m => m.user.id === assigneeId).map(m => areaAndRoleInfo ? areaAndRoleInfo.roleTitles[m.role] : m.role).join(", ")}</span>}
                    </div>
                case PermissionMode.DECIDER_COMPETENCE:
                    return <div>
                        <label>Entscheider wählen</label>
                        <UserSelect valueUserId={targetUserId === -1 ? undefined : targetUserId} users={users} useName={true} onSelect={u => setTargetUserId(u ? u.id : -1)} />
                    </div>
            }
        };

        return <>
            <div className="user-project-selection">
                <div>
                    <label>Modus wählen</label>
                    <Select
                        value={modeOptions.find(mo => mo.value === selectedMode)}
                        options={modeOptions}
                        onChange={(opt) => setSelectedMode(opt!.value)}
                    />
                </div>
                <hr />
                <label>{selectedMode === PermissionMode.LEADER ? "Berechtigung betrifft Support-Partner" : "Berechtigung betrifft Assistenz"}</label>
                <UserSelect annotations={annotations} useName={true} valueUserId={assigneeId > -1 ? assigneeId : undefined} users={users} onSelect={(e) => setAssigneeId(e ? e.id : -1)} />
                <hr />
                {renderDepedentOnMode()}
            </div>
        </>
    };

    const renderCard = () => {
        const userRoles = getUserRoles("token-circalind");
        const allowedRoles: Array<RoleType> = ["CIRCALINDMANAGER", "COORDINATOR", "CO_COORDINATOR"];
        if (userRoles.some(role => allowedRoles.includes(role))) {
            return <div style={{textAlign: "left"}}>
                <div className="filter">
                    <hr />
                    {renderDependentSelection()}
                </div>
                <div className="permission-list">
                    {permissions.length > 0 && ((selectedMode === PermissionMode.LEADER && targetUserId !== -1) || (selectedMode === PermissionMode.DECIDER_PROJECT && projectId !== -1) || (selectedMode === PermissionMode.DECIDER_COMPETENCE && targetUserId !== -1)) ? <>
                        <span></span> <span className="centered">Zugang</span>
                        {renderPermissionCheckboxes()}
                    </> : undefined}
                </div>
                {permissions.length === 0 ? <span className="center-criteria">Keine Berechtigungen verfügbar</span> : undefined}
            </div>
        } else {
            return <span>Nur für die Rollen {allowedRoles.map(r => areaAndRoleInfo ? areaAndRoleInfo.roleTitles[r] : r).join(", ")} verfügbar</span>
        }
    };

    return <div className="list-card">
        <h3>Berechtigungen: Rollen, Räume</h3>
        {renderCard()}
    </div>
}

const addEmpty = (contentAreas: Array<SubArea>, toCheck: Permissions[], projectId: number, internalGroupId: number, userId: number, targetUserId: number, projectSpecific: boolean): Permissions[] => {
    const newPermissions: Array<Permissions> = [];

    contentAreas.forEach(sa => {
        const idx = toCheck.findIndex(t => t.subArea === sa.id);
        if (idx === -1) {
            newPermissions.push({
                id: (-1 * (toCheck.length + 1)),
                area: null,
                subArea: sa.id,
                edit: false,
                internalGroupId: internalGroupId,
                targetUserId: projectSpecific ? -1 : targetUserId,
                projectId: projectSpecific ? projectId : -1,
                userId: userId
            });
        } else {
            newPermissions.push(toCheck[idx]);
        }
    });

    return newPermissions;
}


const loadUserPermissions = async (): Promise<Permissions[]> => {
    const pr = await CircalindApi.getGroupPermissions();
    return pr;
}

type Target = {
    targetProjectId: number | null,
    targetUserId: number | null,
    targetInternalGroupId: number | null,
}

const lookupName = (subAreas: SubArea[], subAreaId: number): string => {
    const sa = subAreas.find(sa => sa.id === subAreaId);
    if (sa) {
        return sa.area + " - " + sa.name;
    } else {
        return "";
    }
}

const filterPermissions = (toFilter: Permissions[], assignee: number, target: Target): Permissions[] => {
    return toFilter
        .filter(p => p.userId === assignee)
        .filter(p => target.targetInternalGroupId === null ? p.internalGroupId === -1 : p.internalGroupId === target.targetInternalGroupId)
        .filter(p => target.targetUserId === null ? p.targetUserId === -1 : p.targetUserId === target.targetUserId)
        .filter(p => target.targetProjectId === null ? p.projectId === -1 : p.projectId === target.targetProjectId);
}


const filterPermissionsByRole = (
    toFilter: Permissions[],
    subAreas: Array<SubArea>,
    assignee: User,
    targetUserId: number,
    project: Project | null,
): Array<Permissions> => {

    if (project) {
        const filterDict: Partial<Record<RoleType, Array<Area>>> = {
            "COORDINATOR": [],
            "CO_MANAGER_PROJECT": ["practicalRoom"],
            "CO_MANAGER_DRIVERS": ["practical"],
            "IMPULSE_PARTNER": ["impulse"],
            "CONTENT_MANAGER": ["governance", "checkups", "service"]
        }
        const assigneeProjectRoles = (project.members.filter(m => m.user.id === assignee.id).map(m => m.role));
        return filterByRolesAndAreasProject(toFilter, subAreas, assigneeProjectRoles, filterDict);
    } else if (targetUserId > -1) {
        const filterDict: { [key in RoleType]?: Array<Area> } = {
            "CONTENT_MANAGER": ["governance"],
            "SUPPORT_PARTNER": ["support"],
            "CO_MANAGER_PROJECT": ["practicalRoom"],
            "CO_MANAGER_DRIVERS": ["practical"],
        }
        return filterByRolesAndAreas(toFilter, subAreas, assignee.roles, filterDict);
    } else {
        const filterDict: { [key in RoleType]?: Array<Area> } = {
            "CONTENT_MANAGER": ["governance"],
            "SUPPORT_PARTNER": ["support"],
        }
        return filterByRolesAndAreas(toFilter, subAreas, assignee.roles, filterDict);
    }
}


const filterPermissionsByRoleForDecider = (
    toFilter: Permissions[],
    subAreas: Array<SubArea>,
    assigner: User,
    assignee: User,
    targetUserId: number,
    project: Project | null,
): Array<Permissions> => {

    if (project) {
        if (project.ownerId !== assigner.id) return [];
        const filterDict: { [key in RoleType]?: Array<Area> } = {
            "CO_MANAGER_PROJECT": ["practicalRoom"],
            "CO_MANAGER_DRIVERS": ["practical"],
        }
        const assigneeProjectRoles = (project.members.filter(m => m.user.id === assignee.id).map(m => m.role));
        return filterByRolesAndAreasProject(toFilter, subAreas, assigneeProjectRoles, filterDict);
    } else if (targetUserId === assignee.id) {
        const filterDict: { [key in RoleType]?: Array<Area> } = {
            "CO_MANAGER_PROJECT": ["practicalRoom"],
            "CO_MANAGER_DRIVERS": ["practical"],
        }
        return filterByRolesAndAreas(toFilter, subAreas, assignee.roles, filterDict);
    } else {
        return [];
    }
}


const filterByRolesAndAreas = (
    toFilter: Permissions[],
    subAreas: Array<SubArea>,
    assigneeRoles: Array<RoleType>,
    allowedArea: { [key in RoleType]?: Array<Area> }
): Array<Permissions> => {
    const areaOfSubarea = new Map(subAreas.map(sa => [sa.id, sa.area]));
    const allowedAreas = assigneeRoles.flatMap(r => allowedArea[r] || []);

    return toFilter.filter(p => {
        const area = areaOfSubarea.get(p.subArea);
        return area && allowedAreas.includes(area);
    });
}

const filterByRolesAndAreasProject = (
    toFilter: Permissions[],
    subAreas: Array<SubArea>,
    assigneeRoles: Array<RoleType>,
    allowedArea: { [key in RoleType]?: Array<Area> }
): Array<Permissions> => {
    const areaOfSubarea = new Map(subAreas.map(sa => [sa.id, sa.area]));
    const allowedAreas = assigneeRoles.flatMap(r => allowedArea[r] || []);

    return toFilter.filter(p => {
        const area = areaOfSubarea.get(p.subArea);
        return area && allowedAreas.includes(area);
    });
}