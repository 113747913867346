import { ReactElement, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SubGraphProps, MovePointState } from "./Graphs";


export const BarGraph = (props: SubGraphProps): ReactElement => {

    const [indexToEdit, setindexToEdit] = useState<MovePointState | undefined>(undefined);

    const barData = () => {
        let res: any[] = [];
        let bar1: any = { name: "soll" }
        let bar2: any = { name: "ist" }
        for (let i = 0; i < props.data.length; i++) {
            let varr1 = "" + i;//props.data[i].uuid;// +"_1";
            let varr2 = "" + i;//props.data[i].uuid;// +"_2";
            bar1[varr1] = props.data[i].points[0].value;
            bar2[varr2] = props.data[i].points[1].value;
            bar1["yaxis"] = "Soll";
            bar2["yaxis"] = "Ist";
        }
        res.push(bar1);
        res.push(bar2);
        return res;
    }

    const onPointMouseUp = () => {
        setindexToEdit(undefined);
    }

    const onPointMouseDown = (index: number, identifier: string) => {
        setindexToEdit({ index: index, identifier: identifier });
    }

    const onPointMove = (e: CategoricalChartState) => {
        if (indexToEdit && indexToEdit.index >= 0 && e && e.chartX) {
            const ele = document.getElementsByClassName("recharts-surface")[0];
            const yaxis = document.getElementsByClassName("recharts-yAxis")[0];
            const width = ele?.getBoundingClientRect().width;
            const offset = (yaxis?.getBoundingClientRect().width);

            if (width && offset) {
                let newData = [...props.data]
                let newLineIndex = newData.findIndex(nd => nd.uuid === indexToEdit.identifier);
                let test = 0;
                newData.forEach((l, i) => i < newLineIndex ? test = test + l.points[indexToEdit.index].value : null)
                let newPointVal = 100 - (100 + (((e.chartX - offset) * (-100)) / width)) - test;
                if (newPointVal > 100) {
                    newPointVal = 100;
                }
                if (newPointVal < 0) {
                    newPointVal = 0;
                }
                if (newLineIndex >= 0) {
                    newData[newLineIndex].points[indexToEdit.index].value = newPointVal;
                    props.updateData(newData);
                }
            }
        }
    }

    const renderCustomBar = (props: any, id: string) => {
        const { name, fill, x, y, width } = props;

        return <g>
            <line x1={x} y1={y + 30} x2={x + width - 15} y2={y + 30} stroke={fill}
                strokeWidth="60" />
            <line x1={x + width - 15} y1={y + 30} x2={x + width} y2={y + 30} stroke={fill}
                strokeWidth="80"
                onMouseDown={() => onPointMouseDown(name === "soll" ? 0 : 1, id)}
            />
        </g>
    }


    const renderBar = () => {
        return props.data.map((l, i) => <Bar
            key={i}
            fill={l.color}
            shape={(p: any) => renderCustomBar(p, l.uuid)}
            stackId={"asdf"}
            dataKey={i}
        />)
    }


    return <ResponsiveContainer>
        <BarChart
            ref={props.innerRef}
            data={barData()}
            layout="vertical" barCategoryGap={20}
            onMouseMove={(e) => onPointMove(e)}
            onMouseUp={() => onPointMouseUp()}
            onMouseLeave={() => onPointMouseUp()}
        >
            <XAxis type="number" interval={0} domain={[0, 100]} />
            <YAxis type="category" dataKey={"yaxis"} />
            {renderBar()}
        </BarChart>
    </ResponsiveContainer>

}