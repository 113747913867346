import { Dispatch, ReactElement, SetStateAction, createContext, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Login } from "./circalind/login/Login";
import './circalind/circalind.scss'
import { SecureRouteCircalind } from "./circalind/route/SecureRouteCircalind";
import { Footer } from "./circalind/page/footer/Footer";
import { Settings } from "./circalind/profile/Settings";
import { Logout } from "./circalind/logout/Logout";
import { defaultHeaders, Header, HeaderContext, HeaderMenuItem } from "./circalind/page/header/Header";
import { ResetPass } from "./circalind/login/ResetPass";
import { Phase } from "./circalind/projects/phases/Phase";
import { AdminOverview } from "./circalind/admin/AdminOverview";
import { NotFound } from "./NotFound";
import { AdminOnly, ContentCreators } from "./circalind/route/allowedUserGroups";
import { LandingPage } from "./circalind/landingPage/LandingPage";
import { Impressum } from "./Impressum";
import { Datenschutz } from "./Datenschutz";
import { Forbidden } from "./Forbidden";
import { DeepIntro } from "./circalind/projects/compass/cockpit/DeepIntro";
import ModalContainer from 'react-modal-promise'
import { UpdatePass } from "./circalind/login/UpdatePass";
import { GeneralContentEditor } from "./circalind/contentManager/GeneralContentEditor";
import { Menu } from "./circalind/page/menus/Menu";
import { Staffing } from "./leading/staffing/Staffing";
import { LeadingStart } from "./leading/Start";
import { PhaseStart } from "./circalind/projects/phases/PhaseStart";
import { Control } from "./circalind/projects/controls/Control";
import { ProjectDesk } from "./circalind/projects/desk/ProjectDesk";
import { PhaseInfoBlock } from "./circalind/contentManager/Info";
import { ExcelList, LeadingDocumentation } from "./leading/documentation/LeadingDocumentation";
import { ITCoordination } from "./leading/toolbox/ITCoordination";
import { Profile } from "./circalind/services/Profile";
import { Polls } from "./circalind/communication/polls/Polls";
import { QualityManagement } from "./leading/quality/quality";
import { Planning } from "./circalind/collaberation/Planning";
import { Construction } from "./circalind/collaberation/Construction";
import { Simulation } from "./circalind/collaberation/Simulation";
import { PrePhase } from "./circalind/projects/phases/prePhase/PrePhase";
import { SynchronimeterAndControls } from "./circalind/projects/phases/prePhase/synchro/SynchronimeterAndControls";
import { FAQ } from "./leading/documentation/faq";
import { PostPhaseSelection } from "./circalind/projects/phases/postPhase/PostPhaseSelection";
import { PostPhase } from "./circalind/projects/phases/postPhase/PostPhase";
import { InfoServices } from "./circalind/infoServices/InfoServices";
import { ServiceOverview } from "./circalind/services/ServiceOverview";
import { WayBooklet } from "./circalind/booklet/WayBooklet";
import { RoomChange } from "./circalind/functional/RoomChange";
import { GovernanceView } from "./circalind/governance/GovernanceView";
import { Drivers } from "./circalind/practical/Drivers";
import { PracticalContent } from "./circalind/practical/PracticalContent";
import { CurrentProjectRadar } from "./circalind/projects/phases/PhaseRadar";
import { WelcomeITCoordination } from "./leading/toolbox/WelcomeITCoordination";
import { WelcomeQuality } from "./leading/quality/WelcomeQuality";
import { WelcomeLeadingDocumentation } from "./leading/documentation/WelcomeLeadingDocumentation";
import { WelcomeStaffing } from "./leading/staffing/WelcomeStaffing";
import { WelcomeRegularDocumentation } from "./circalind/documentation/WelcomeRegularDocumentation";
import { RegularDocumentation } from "./circalind/documentation/RegularDocumentation";
import { Checkups } from "./circalind/checkups/CheckUps";
import { WelcomeGovernance } from "./circalind/governance/WelcomeGovernance";
import { WelcomePractical } from "./circalind/practical/WelcomePractical";
import { WelcomeCockpit } from "./circalind/projects/compass/cockpit/WelcomeCockpit";
import { WelcomeCommunication } from "./circalind/communication/WelcomeCommunication";
import { WelcomeCompetence } from "./circalind/projects/competence/WelcomeCompetence";
import { Impulse } from "./circalind/impulse/Impulse";
import { IdView } from "./circalind/id/Id";
import { ContractView } from "./circalind/services/Contract";
import { UserOverview, UsersNewPage } from "./leading/staffing/UserOverview";
import { Managing } from "./circalind/Managing/Managing";
import { ChooseLicense } from "./circalind/login/ChooseLicense";
import { ProjectOverview } from "./circalind/Managing/ProjectOverview";
import { OrderView } from "./circalind/services/OrderView";
import { OrderConfirmation } from "./circalind/services/OrderConfirmation";
import { BlueFooter, CommunicationFooter, DashboardFooter, LeftCorner, RightCorner, YellowFooter } from "./resources/Images";
import { CompetenceOverview } from "./circalind/projects/competence/CompetenceOverview";
import { PhaseOverview } from "./circalind/projects/phases/PhaseOverview";
import { CompetenceQuestion } from "./circalind/projects/competence/CompetenceQuestion";
import { Talents } from "./circalind/projects/competence/subareas/Talents";
import { CockpitOverview } from "./circalind/projects/compass/cockpit/CockpitOverview";
import { DeepOverview } from "./circalind/projects/compass/cockpit/DeepOverview";
import { OneView } from "./circalind/projects/compass/cockpit/OneView";
import { LeadingInfoOverview } from "./circalind/info/LeadingInfoOverview";
import { ProjectHistory } from "./circalind/documentation/ProjectHistory";
import { ProjectDashboard } from "./circalind/projects/compass/cockpit/ProjectDashboard";
import { OneViewSynchronimeter, ProjectBaseDrivers, ProjectBaseImportance, ProjectBaseInterjection, ProjectBaseLaneChange, ProjectBaseManage, ProjectBaseStart, ProjectBaseTime } from "./circalind/projects/compass/cockpit/ProjectBase";
import { GlobalDialogContainer } from "./circalind/basicComponents/dialogs/GlobalDialogContainer";
import { ProjectCommunications } from "./circalind/Managing/ProjectCommunication";
import { ModuleOverview } from "./leading/staffing/ModuleOverview";
import { LeadingInfo } from "./leading/LeadingInfo";
import { DeciderStart } from "./circalind/decider/Start";
import { DeciderInfo } from "./circalind/decider/DeciderInfo";
import { PracticalContentPersonal } from "./circalind/practical/PracticalContentPersonal";
import { DocumentationDesk, DocumentationDeskType } from "./circalind/documentation/DocumentationDesk";
import { ProjectFamilyOverview } from "./circalind/projects/compass/cockpit/ProjectFamilyOverview";
import { InteractionOverview } from "./circalind/communication/InteractionOverview";
import { MatchingOverview } from "./circalind/checkups/MatchingOverview";
import { ToolPermissions } from "./leading/staffing/ToolPermissions";
import { useSelectedProject } from "./utils/Hooks";
import { MatchingDecider } from "./circalind/checkups/MatchingDecider";
import { MatchingProjectComplete } from "./circalind/checkups/MatchingProjectComplete";
import { MatchingProjectPhase } from "./circalind/checkups/MatchingProjectPhase";
import { CommunicationOverview } from "./circalind/communication/CommunicationOverview";
import { CommunicationContent } from "./circalind/communication/CommunicationContent";
import { PackageOptions } from "./leading/PackageOptions";
import { WelcomeServices } from "./circalind/infoServices/WelcomeServices";
import { PersonalDashboard } from "./circalind/projects/compass/cockpit/PersonalDashboard";
import { ManagingToolbox } from "./circalind/Managing/ManagingToolbox";
import { PhaseDiagrams } from "./circalind/projects/competence/PhaseDiagrams";
import { getCurrentUserId } from "./utils/Auth";
import { StaffingAndSettings } from "./leading/staffing/StaffingAndSettings";
import { TransferOptions } from "./leading/staffing/TransferOptions";
import { Transfer } from "./circalind/practical/transfer/Transfer";
import { MyCustomerView } from "./circalind/login/MyCustomerView";
import { CustomerOrderOverview } from "./circalind/services/CustomerOrderView";
import { CustomerPackageOverview } from "./circalind/services/CustomerPackageOverview";


export const CircaLindToken: string = "token-circalind";

export const ImpulseContext = createContext<{ impulseColor: string | undefined, setImpulseColor: Dispatch<SetStateAction<string | undefined>> } | undefined>(undefined);

export const MainCircaLind = (): ReactElement => {

    const loc: string = "circalind";
    const location = useLocation();
    const [headers, updateHeader] = useState<HeaderMenuItem[]>(defaultHeaders)
    const [impulseColor, setImpulseColor] = useState<string | undefined>(undefined);

    const [selectedProject,] = useSelectedProject();

    const getBackgroundImage = () => {
        const createInlineStyle = (imgSrc: string, height: number) => {
            return { backgroundImage: `url(${imgSrc})`, backgroundSize: `100% ${height}%`, backgroundRepeat: "no-repeat", backgroundPosition: "bottom" };
        }

        const yellowLocations = ["/circalind/regular/competence"];
        const blueLocations = ["/circalind/regular/phase", "/circalind/regular/prePhase", "/circalind/regular/postPhase"];
        const drawnLocations = ["/circalind/regular/dashboard"];
        const communicationLocations = ["/circalind/regular/communication"];

        if (!location.pathname.endsWith("/start")) {
            if (yellowLocations.find(l => location.pathname.startsWith(l))) {
                return createInlineStyle(YellowFooter, 50);
            } else if (blueLocations.find(l => location.pathname.startsWith(l))) {
                return createInlineStyle(BlueFooter, 10);
            } else if (drawnLocations.find(l => location.pathname.startsWith(l))) {
                return createInlineStyle(DashboardFooter, 10);
            } else if (communicationLocations.find(l => location.pathname.startsWith(l))) {
                return createInlineStyle(CommunicationFooter, 40);
            }
        }
    }

    const renderImpulseFooter = () => {
        const impulseLocations = ["/circalind/regular/impulse"];

        if (impulseLocations.find(l => location.pathname.startsWith(l))) {
            const style = { height: "50px", width: "50px", backgroundColor: impulseColor, WebkitMaskMode: "alpha", maskMode: "alpha", WebkitMaskImage: `url(${LeftCorner})`, maskImage: `url(${LeftCorner})`, WebkitMaskSize: `100% 100%`, maskSize: `100% 100%`, WebkitMaskRepeat: "no-repeat", maskRepeat: "no-repeat", WebkitMaskPosition: "bottom", maskPosition: "bottom" };
            return <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div style={style}></div>
                <div style={{ ...style, WebkitMaskImage: `url(${RightCorner})`, maskImage: `url(${RightCorner})` }}></div>
            </div>
        }
    };

    return (
        <div style={getBackgroundImage()} className="App-Circalind">
            <ImpulseContext.Provider value={{ impulseColor, setImpulseColor }}>
                <HeaderContext.Provider value={{ headers, updateHeader }}>
                    <Header />
                    <div className="app-body">
                        <Routes>
                            <Route path={`${loc}/test`} element={<LeadingInfoOverview edit={true} />} />
                            <Route path={`${loc}/login`}>
                                <Route index element={<Login />} />
                                <Route path=":target" element={<Login />} />
                            </Route>

                            <Route path={`${loc}/services/choose-license`}>
                                <Route index element={<ChooseLicense />} />
                                <Route path=":target" element={<ChooseLicense />} />
                            </Route>

                            <Route path={`${loc}/reset`} element={<ResetPass />} />
                            <Route path={`${loc}/user/reset/:token`} element={<UpdatePass />} />
                            <Route path={`${loc}/logout`} element={<Logout />} />
                            <Route path={`${loc}/impressum`} element={<Impressum />} />
                            <Route path={`${loc}/dataprivacy`} element={<Datenschutz />} />

                            <Route path={`${loc}/`} element={<LandingPage />} />
                            <Route path={`/`} element={<LandingPage />} />

                            <Route path={`${loc}/admin/*`} element={<SecureRouteCircalind children={<AdminOverview />} allowedUsers={AdminOnly} />} />

                            <Route path={`${loc}/leading`}>
                                <Route path={"desk"} element={<SecureRouteCircalind><Menu><ProjectDesk projectSpecific={false} /></Menu></SecureRouteCircalind>} />
                                <Route path={"start"} element={<SecureRouteCircalind><Menu><LeadingStart /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"info"} element={<SecureRouteCircalind><Menu><LeadingInfo /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"content"} element={<SecureRouteCircalind><Menu><GeneralContentEditor /></Menu></SecureRouteCircalind>} />
                                <Route path={"staffing"}>
                                    <Route index element={<Navigate replace to="/circalind/leading/staffing/start" />}></Route>
                                    <Route path={"options"} element={<SecureRouteCircalind><Menu><PackageOptions /></Menu></SecureRouteCircalind>} />
                                    <Route path={"start"} element={<SecureRouteCircalind><Menu><WelcomeStaffing /></Menu></SecureRouteCircalind>} />
                                    <Route path={"overview"} element={<SecureRouteCircalind><Menu><StaffingAndSettings /></Menu></SecureRouteCircalind>} />
                                    <Route path={"projects"} element={<SecureRouteCircalind><Menu><Staffing /></Menu></SecureRouteCircalind>} />
                                    <Route path={"users/new"} element={<SecureRouteCircalind><Menu><UsersNewPage /></Menu></SecureRouteCircalind>} />
                                    <Route path={"users"} element={<SecureRouteCircalind><Menu><UserOverview /></Menu></SecureRouteCircalind>} />
                                    <Route path={"module"} element={<SecureRouteCircalind><Menu><ModuleOverview /></Menu></SecureRouteCircalind>} />
                                    <Route path={"tools"} element={<SecureRouteCircalind><Menu><ToolPermissions /></Menu></SecureRouteCircalind>} />
                                    <Route path={"transfer"} element={<SecureRouteCircalind><Menu><TransferOptions /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"documentation"}>
                                    <Route index element={<Navigate replace to="/circalind/leading/documentation/start" />}></Route>
                                    <Route path={"start"} element={<SecureRouteCircalind><Menu><WelcomeLeadingDocumentation /></Menu></SecureRouteCircalind>} />
                                    <Route path={"main"} element={<SecureRouteCircalind><Menu><LeadingDocumentation /></Menu></SecureRouteCircalind>} />
                                    <Route path={"faq"} element={<SecureRouteCircalind><Menu><FAQ /></Menu></SecureRouteCircalind>} />
                                    <Route path={"excel"} element={<SecureRouteCircalind><Menu><ExcelList /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"toolbox"}>
                                    <Route index element={<Navigate replace to="/circalind/leading/toolbox/start" />}></Route>
                                    <Route path={"main"} element={<SecureRouteCircalind><Menu><ITCoordination /></Menu></SecureRouteCircalind>} />
                                    <Route path={"start"} element={<SecureRouteCircalind><Menu><WelcomeITCoordination /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"quality"}>
                                    <Route index element={<Navigate replace to="/circalind/leading/quality/start" />}></Route>
                                    <Route path={"start"} element={<SecureRouteCircalind><Menu><WelcomeQuality /></Menu></SecureRouteCircalind>} />
                                    <Route path={"main"} element={<SecureRouteCircalind><Menu><QualityManagement /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={`collaboration`}>
                                    <Route path={"planning"} element={<SecureRouteCircalind><Menu><Planning /></Menu></SecureRouteCircalind>} />
                                    <Route path={"construction"} element={<SecureRouteCircalind><Menu><Construction /></Menu></SecureRouteCircalind>} />
                                    <Route path={"simulation"} element={<SecureRouteCircalind><Menu><Simulation /></Menu></SecureRouteCircalind>} />
                                </Route>
                            </Route>
                            <Route path={`${loc}/regular`}>
                                <Route index element={<Navigate replace to="/circalind/regular/start" />}></Route>
                                <Route path={"start"} element={<SecureRouteCircalind><Menu><DeciderStart /></Menu></SecureRouteCircalind>} />
                                <Route path={"info"} element={<SecureRouteCircalind><Menu><DeciderInfo /></Menu></SecureRouteCircalind>} />
                                <Route path={"desk"} element={<SecureRouteCircalind><Menu><ProjectDesk projectSpecific={true} /></Menu></SecureRouteCircalind>} />
                                <Route path={`desk/waybooklet`} element={<SecureRouteCircalind><Menu><WayBooklet /></Menu></SecureRouteCircalind>} />
                                <Route path={"governance"}>
                                    <Route index element={<Navigate replace to="/circalind/regular/governance/start" />}></Route>
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomeGovernance /></Menu></SecureRouteCircalind>} />
                                    <Route path={"main"} element={<SecureRouteCircalind><Menu><GovernanceView /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"communication"}>
                                    <Route index element={<Navigate replace to="/circalind/regular/communication/start" />}></Route>
                                    <Route path={`main`} element={<SecureRouteCircalind><Menu><CommunicationOverview /></Menu></SecureRouteCircalind>} />
                                    <Route path={`project`} element={<SecureRouteCircalind><Menu><CommunicationContent area={"communicationProject"} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`prepost`} element={<SecureRouteCircalind><Menu><CommunicationContent area={"communicationPrepost"} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomeCommunication /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"menu"} element={<SecureRouteCircalind><Menu><div></div></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"dashboard"} >
                                    <Route index element={<Navigate replace to="/circalind/regular/dashboard/start" />}></Route>
                                    <Route path={`radar`} element={<SecureRouteCircalind><Menu><CurrentProjectRadar /></Menu></SecureRouteCircalind>} />
                                    <Route path={"main"} element={<SecureRouteCircalind><Menu><CockpitOverview /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={"one"} element={<SecureRouteCircalind><Menu><OneView /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={"personal"} element={<SecureRouteCircalind><Menu><PersonalDashboard /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={"families"} element={<SecureRouteCircalind><Menu><ProjectFamilyOverview /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={"synchro"} element={<SecureRouteCircalind><Menu><SynchronimeterAndControls /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={"base"}>
                                        <Route index element={<Navigate replace to="/circalind/regular/dashboard/base/start" />}></Route>
                                        <Route path={"synchro"} element={<SecureRouteCircalind><Menu><OneViewSynchronimeter /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"start"} element={<SecureRouteCircalind><Menu><ProjectBaseStart /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"time"} element={<SecureRouteCircalind><Menu><ProjectBaseTime /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"importance"} element={<SecureRouteCircalind><Menu><ProjectBaseImportance /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"drivers"} element={<SecureRouteCircalind><Menu><ProjectBaseDrivers /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"interjection"} element={<SecureRouteCircalind><Menu><ProjectBaseInterjection /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"pre/manage"} element={<SecureRouteCircalind><Menu><ProjectBaseManage /></Menu></SecureRouteCircalind>}></Route>
                                        <Route path={"pre/lane"} element={<SecureRouteCircalind><Menu><ProjectBaseLaneChange /></Menu></SecureRouteCircalind>}></Route>
                                    </Route>
                                    <Route path={"dashboard"} element={<SecureRouteCircalind><Menu><ProjectDashboard /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={"deep"} element={<SecureRouteCircalind><Menu><DeepOverview /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomeCockpit /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"practical"} element={<Outlet />}>
                                    <Route index element={<Navigate replace to="/circalind/regular/practical/start" />}></Route>
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomePractical /></Menu></SecureRouteCircalind>} />
                                    <Route path={"drivers"} element={<SecureRouteCircalind><Menu><Drivers /></Menu></SecureRouteCircalind>} />
                                    <Route path={"content"} element={<Outlet />}>
                                        <Route path={"general"} element={<SecureRouteCircalind><Menu><PracticalContent /></Menu></SecureRouteCircalind>} />
                                        <Route path={"personal"} element={<SecureRouteCircalind><Menu><PracticalContentPersonal /></Menu></SecureRouteCircalind>} />
                                    </Route>
                                </Route>
                                <Route path={"postPhase"} element={<Outlet />}>
                                    <Route index element={<SecureRouteCircalind><Menu><PostPhaseSelection /></Menu></SecureRouteCircalind>} />
                                    <Route path={"feedback"} element={<SecureRouteCircalind><Menu><PostPhase edit={true} phaseType="Feedback" /></Menu></SecureRouteCircalind>} />
                                    <Route path={"change"} element={<SecureRouteCircalind><Menu><PostPhase edit={true} phaseType="Wandel" /></Menu></SecureRouteCircalind>} />
                                    <Route path={"layout"} element={<SecureRouteCircalind><Menu><PostPhase edit={true} phaseType="Gestaltung" /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={"phase"}>
                                    <Route index element={<Navigate replace to="/circalind/regular/phase/start" />}></Route>
                                    <Route path={"overview"} element={<SecureRouteCircalind><Menu><PhaseOverview /></Menu></SecureRouteCircalind>} />
                                    <Route path={"start"} element={<SecureRouteCircalind><Menu><PhaseStart /></Menu></SecureRouteCircalind>} />
                                    <Route path={"info"} element={<SecureRouteCircalind><Menu><PhaseInfoBlock /></Menu></SecureRouteCircalind>} />
                                    <Route path={"decide"} element={<SecureRouteCircalind><Menu><Control /></Menu></SecureRouteCircalind>} />
                                    <Route path={":phaseID"} element={<SecureRouteCircalind><Menu><Phase /></Menu></SecureRouteCircalind>} />
                                    <Route path={"compass"} element={<Outlet />}>
                                        <Route path={"self"} element={<SecureRouteCircalind><Menu><PrePhase phaseType="Selbstführung" /></Menu></SecureRouteCircalind>} />
                                        <Route path={"manage"} element={<SecureRouteCircalind><Menu><PrePhase phaseType="Management" /></Menu></SecureRouteCircalind>} />
                                        <Route path={"lane"} element={<SecureRouteCircalind><Menu><PrePhase phaseType="Spur-Wechsel" /></Menu></SecureRouteCircalind>} />
                                    </Route>
                                </Route>
                                <Route path={`transform/:route`} element={<SecureRouteCircalind><DeepIntro edit={false} /></SecureRouteCircalind>} />
                                <Route path={`services`}>
                                    <Route index element={<Navigate replace to="/circalind/regular/services/start" />}></Route>
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomeServices /></Menu></SecureRouteCircalind>} />
                                    <Route path={`main`} element={<SecureRouteCircalind><Menu><InfoServices /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={`impulse`} element={<SecureRouteCircalind><Menu><Impulse /></Menu></SecureRouteCircalind>} />
                                <Route path={`documentation`}>
                                    <Route index element={<Navigate replace to="/circalind/regular/documentation/start" />}></Route>
                                    <Route path={`main`} element={<SecureRouteCircalind><Menu><RegularDocumentation /></Menu></SecureRouteCircalind>} />
                                    <Route path={`history`} element={<SecureRouteCircalind><Menu><ProjectHistory /></Menu></SecureRouteCircalind>} />
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomeRegularDocumentation /></Menu></SecureRouteCircalind>} />
                                    <Route path={`practises`} element={<SecureRouteCircalind><Menu><DocumentationDesk deskType={DocumentationDeskType.BEST_PRACTISE} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`presentations`} element={<SecureRouteCircalind><Menu><DocumentationDesk deskType={DocumentationDeskType.PRESENTATION_SHOP} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`collection`} element={<SecureRouteCircalind><Menu><DocumentationDesk deskType={DocumentationDeskType.MY_COLLECTION} /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={`competence`}>
                                    <Route index element={<Navigate replace to="/circalind/regular/competence/start" />}></Route>
                                    <Route path={`talents`} element={<SecureRouteCircalind><Menu><Talents /></Menu></SecureRouteCircalind>} />
                                    <Route path={`content/individual`} element={<SecureRouteCircalind><Menu><CompetenceQuestion name="PERSONAL_COMPETENCE" notes={false} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`content/team`} element={<SecureRouteCircalind><Menu><CompetenceQuestion name="PROJECT_PHASE_COMPETENCE" notes={false} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`individual`} element={<SecureRouteCircalind><Menu><PhaseDiagrams graphForChoices={["PERSONAL_COMPETENCE"]} chooseProject={false} userId={getCurrentUserId("token-circalind") || -1} authorId={getCurrentUserId("token-circalind") || -1} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`team`} element={<SecureRouteCircalind><Menu><PhaseDiagrams graphForChoices={["PROJECT_PHASE_COMPETENCE"]} projectId={selectedProject} chooseProject={false} userId={getCurrentUserId("token-circalind") || -1} authorId={getCurrentUserId("token-circalind") || -1} /></Menu></SecureRouteCircalind>} />
                                    <Route path={`complete`} element={<SecureRouteCircalind><Menu><PhaseDiagrams graphForChoices={["PROJECT_COMPETENCE"]} projectId={selectedProject} chooseProject={false} userId={getCurrentUserId("token-circalind") || -1} authorId={getCurrentUserId("token-circalind") || -1} /></Menu></SecureRouteCircalind>} />
                                    <Route path={"content"} element={<SecureRouteCircalind><Menu><CompetenceOverview /></Menu></SecureRouteCircalind>}></Route>
                                    <Route path={`start`} element={<SecureRouteCircalind><Menu><WelcomeCompetence /></Menu></SecureRouteCircalind>} />
                                </Route>
                                <Route path={`sync`} element={<SecureRouteCircalind><Menu><SynchronimeterAndControls /></Menu></SecureRouteCircalind>} />
                                <Route path={`checkups/content`} element={<SecureRouteCircalind><Menu><Checkups /></Menu></SecureRouteCircalind>} />
                                <Route path={`checkups/matching`} element={<SecureRouteCircalind><Menu><MatchingOverview /></Menu></SecureRouteCircalind>} />
                                <Route path={`checkups/matching/decider`} element={<SecureRouteCircalind><Menu><MatchingDecider /></Menu></SecureRouteCircalind>} />
                                <Route path={`checkups/matching/project/complete`} element={<SecureRouteCircalind><Menu><MatchingProjectComplete /></Menu></SecureRouteCircalind>} />
                                <Route path={`checkups/matching/project/phases`} element={<SecureRouteCircalind><Menu><MatchingProjectPhase /></Menu></SecureRouteCircalind>} />
                            </Route>

                            <Route path={`${loc}/managing`}>
                                <Route index element={<Navigate replace to="/circalind/managing/overview" />}></Route>
                                <Route path={"overview"} element={<SecureRouteCircalind><Menu><Managing /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"toolbox"} element={<SecureRouteCircalind><Menu><ManagingToolbox /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"communicate"} element={<SecureRouteCircalind><Menu><ProjectCommunications /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"projects"} element={<SecureRouteCircalind><Menu><ProjectOverview /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"transfer"} element={<SecureRouteCircalind><Menu><Transfer /></Menu></SecureRouteCircalind>}></Route>
                            </Route>

                            <Route path={`${loc}/interaction`} element={<SecureRouteCircalind><Menu><InteractionOverview /></Menu></SecureRouteCircalind>} />
                            <Route path={`${loc}/interaction/polls`} element={<SecureRouteCircalind><Menu><Polls /></Menu></SecureRouteCircalind>} />

                            <Route path={`${loc}/services`}>
                                <Route path={"room"} element={<SecureRouteCircalind><Menu><RoomChange /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"start"} element={<SecureRouteCircalind><Menu><ServiceOverview></ServiceOverview></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"profile"} element={<SecureRouteCircalind><Menu><Profile></Profile></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"id"} element={<SecureRouteCircalind><Menu><IdView /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"contract"} element={<SecureRouteCircalind><Menu><ContractView /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={"users"} element={<SecureRouteCircalind><Menu><UserOverview /></Menu></SecureRouteCircalind>}></Route>
                                <Route path={`order`} element={<OrderView />} />
                                <Route path={`order/overview`} element={<CustomerOrderOverview />} />
                                <Route path={`package/overview`} element={<CustomerPackageOverview />} />
                                <Route path={`confirmation`} element={<OrderConfirmation />} />
                                <Route path={`customer`} element={<MyCustomerView />} />
                            </Route>

                            <Route path={`${loc}/contentmanager/settings`} element={<SecureRouteCircalind children={<Settings />} allowedUsers={ContentCreators} />} />

                            <Route path={`${loc}/content/phase/edit/:phaseID`} element={<SecureRouteCircalind children={<Phase editMode={true} />} allowedUsers={ContentCreators} />} />

                            <Route path={`${loc}/forbidden`} element={<SecureRouteCircalind><Forbidden /></SecureRouteCircalind>} />


                            <Route path="*" element={<NotFound />} />
                        </Routes>
                        <div>{renderImpulseFooter()}</div>
                    </div>
                </HeaderContext.Provider>
                <ModalContainer />
                <GlobalDialogContainer />
                <Footer />
            </ImpulseContext.Provider>
        </div>

    );
}