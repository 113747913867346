import { useEffect, useState } from "react";
import { Base64Upload } from "../circalind/basicComponents/base64Upload/Base64Upload";
import { AllowedPollResult, AllowedProjectDepth, AllowedProjectType, AreaAndRoleInfo, Group, RoleType } from "../circalind/dataTypes/generated";
import { CircalindApi } from "../circalind/circalindApi";
import Select from 'react-select';
import { sendOlert } from "../olert/Olert";

import strategyScreen from "../resources/circa/options/strategy_screen.png";
import personalScreen from "../resources/circa/options/personal_screen.png";
import informationImage from "../resources/circa/information.png";

import managementImage from "../resources/circa/compass/ManagementCompass.png";
import spurImage from "../resources/circa/compass/SpurWechsel.png";
import selbstImage from "../resources/circa/compass/Selbstführung.png";

import { LeadingModeHeader } from "../circalind/page/menus/Menu";
import { useNavigate } from "react-router";
import { RoleDisplay } from "./role";
import { areaImages } from "./areas";

import simulationImage from "../resources/circa/simulation.svg";
import planningImage from "../resources/circa/planning.svg";
import constructionImage from "../resources/circa/construction.svg";

import "./PackageOptions.scss";

export const PackageOptions = () => {
    const [group, setGroup] = useState<Group | null>(null);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);

    const navigate = useNavigate();

    const loadGroup = async () => {
        setGroup(await CircalindApi.getMyGroup());
    };

    const loadAreaAndRoleInfo = async () => {
        setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
    };

    useEffect(() => {
        loadGroup();
        loadAreaAndRoleInfo();
    }, []);


    const renderLogoChange = () => {
        const uploadLogo = async (logo: string) => {
            if (group) {
                if (await CircalindApi.updateGroupLogo({ groupId: group.id, logo: logo })) {
                    await loadGroup();
                }
            }
        };

        return <div className="group-logo">
            {group && group.logo && <img src={group.logo} alt="Logo"></img>}
            <Base64Upload onUpload={uploadLogo} type={"IMAGE"} />
        </div>
    };

    const renderAllowedProjectType = () => {
        const options = [
            { label: "Persönlich", value: "PERSONAL" as AllowedProjectType },
            { label: "Strategisch", value: "STRATEGIC" as AllowedProjectType },
            { label: "Beide", value: "BOTH" as AllowedProjectType }
        ];

        const updateGroup = async () => {
            if (group) {
                const result = await CircalindApi.updateGroup(group);

                if (result) {
                    sendOlert("Einstellung geändert", "Einstellung erfolgreich geändert", "Success");
                }
            }
        };

        return <div className="allowed-project-type">
            <div className="drop-down">
                {group && <Select value={options.find(o => o.value === group.allowedProjectType)} className="add-role" onChange={(opt) => setGroup({ ...group, allowedProjectType: opt!.value })} options={options}></Select>}
            </div>
            <button onClick={updateGroup}>Speichern</button>
            <h3>Vorschau 3. Praxis-Raum</h3>
            <div className="preview">
                {group && (group.allowedProjectType === "BOTH" || group.allowedProjectType === "STRATEGIC") && <img src={strategyScreen} alt="" />}
                {group && (group.allowedProjectType === "BOTH" || group.allowedProjectType === "PERSONAL") && <img src={personalScreen} alt="" />}
            </div>
        </div>;
    };

    const renderAllowedProjectDepth = () => {
        const options = [
            { label: "Kompakt (inkl. Projekt-Weg)", value: "COMPACT" as AllowedProjectDepth },
            { label: "Vertiefend (inkl. Mein Weg)", value: "EXTENSIVE" as AllowedProjectDepth },
            { label: "Beide", value: "BOTH" as AllowedProjectDepth }
        ];

        const updateGroup = async () => {
            if (group) {
                const result = await CircalindApi.updateGroup(group);

                if (result) {
                    sendOlert("Einstellung geändert", "Einstellung erfolgreich geändert", "Success");
                }
            }
        };

        return <div className="allowed-project-type">
            <div className="drop-down">
                {group && <Select value={options.find(o => o.value === group.allowedProjectDepth)} className="add-role" onChange={(opt) => setGroup({ ...group, allowedProjectDepth: opt!.value })} options={options}></Select>}
            </div>
            <button onClick={updateGroup}>Speichern</button>
        </div>;
    };

    const renderModeChoice = () => {
        if (group) {
            return <LeadingModeHeader edit={true} group={group} updateGroup={loadGroup} />
        }
    };

    const renderAllowedPollRoles = () => {
        const deciderRoles: Array<RoleType> = ["DECIDER", "IMPULSE_PARTNER", "CONTENT_MANAGER", "CO_MANAGER_DRIVERS", "CO_MANAGER_PROJECT"];
        const leadingRoles: Array<RoleType> = ["COORDINATOR", "CO_COORDINATOR", "IT_COORDINATOR", "QM_COORDINATOR", "SUPPORT_PARTNER"];

        const updateGroup = async () => {
            if (group) {
                const result = await CircalindApi.updateGroup(group);

                if (result) {
                    sendOlert("Einstellung geändert", "Einstellung erfolgreich geändert", "Success");
                }
            }
        };

        const renderAllowedPollResult = () => {
            const options = [
                { label: "Alle Teilnehmer", value: "ALL_PARTICIPANTS" as AllowedPollResult },
                { label: "Nur Autor", value: "AUTHOR" as AllowedPollResult },
            ];

            if (group) {
                return <Select value={options.find(o => o.value === group?.allowedPollResult)} className="add-role" onChange={(opt) => setGroup({ ...group, allowedPollResult: opt!.value })} options={options}></Select>
            }
        };

        if (areaAndRoleInfo && group) {
            return <div className="roles">
                <div className="horizontal">
                    <div>
                        <h3>Führung & Zusammenarbeit</h3>
                        {leadingRoles.map(r => {
                            const callback = () => setGroup({ ...group, allowedPollRoles: group.allowedPollRoles.includes(r) ? group.allowedPollRoles.filter(gr => gr !== r) : [...group.allowedPollRoles, r] });
                            return <div key={r}>
                                <input id={r} onChange={callback} checked={group.allowedPollRoles.includes(r)} type="checkbox" />
                                <label style={{ color: "black" }} htmlFor={r}><RoleDisplay areaAndRoleInfo={areaAndRoleInfo} role={r} /></label>
                            </div>
                        })}
                    </div>
                    <div>
                        <h3>Entscheider-Welt</h3>
                        {deciderRoles.map(r => {
                            const callback = () => setGroup({ ...group, allowedPollRoles: group.allowedPollRoles.includes(r) ? group.allowedPollRoles.filter(gr => gr !== r) : [...group.allowedPollRoles, r] });
                            return <div key={r}>
                                <input id={r} onChange={callback} checked={group.allowedPollRoles.includes(r)} type="checkbox" />
                                <label style={{ color: "black" }} htmlFor={r}><RoleDisplay areaAndRoleInfo={areaAndRoleInfo} role={r} /></label>
                            </div>
                        })}
                    </div>
                    <div>
                        <h3>Umfrageergebnisse können folgende Teilnehmer sehen</h3>
                        {renderAllowedPollResult()}
                    </div>
                </div>
                <div className="save-button">
                    <button onClick={updateGroup}>Speichern</button>
                </div>
            </div>
        }
    };

    return <div className="package-options">
        <h1>Individualisierung</h1>
        <div className="card">
            <h2>Unternehmens-Logo</h2>
            {renderLogoChange()}
        </div>
        <div className="card">
            <h2>Modus-Wahl</h2>
            {renderModeChoice()}
        </div>
        <div className="card">
            <h2>Projekt-Typ autorisieren</h2>
            {renderAllowedProjectType()}
        </div>
        <div className="card">
            <h2>Projekt-Weg autorisieren</h2>
            {renderAllowedProjectDepth()}
        </div>
        <div className="card">
            <h2>Erlaubte Rollen in Umfragen</h2>
            {renderAllowedPollRoles()}
        </div>
        <div className="card">
            <h2>Redaktionelle Inhalte</h2>
            <div>
                <div>
                    <h3>Ansprache zu Einsatz & Rolle</h3>
                    <div className="content-cards">
                        <div onClick={() => navigate("../../content")} className="card clickable">
                            <img src={informationImage} alt="" />
                        </div>
                    </div>
                </div>
                <div>
                    <h3>Im Entscheider-Bereich zum Vertiefen</h3>
                    <div className="content-cards">
                        <div onClick={() => navigate("../../content")} className="card clickable">
                            <img src={selbstImage} alt="" />
                        </div>
                        <div onClick={() => navigate("../../content")} className="card clickable">
                            <img src={managementImage} alt="" />
                        </div>
                        <div onClick={() => navigate("../../content")} className="card clickable">
                            <img src={spurImage} alt="" />
                        </div>
                    </div>
                </div>
                <div>
                    <h3>Phasen-Inhalte</h3>
                    <div className="content-cards">
                        <div onClick={() => navigate("../content")} className="card clickable">
                            <img src={areaImages["phase"]} alt="" />
                        </div>
                    </div>
                </div>
                <div>
                    <h3>Planung-, Aufbau- und Reallabor-Inhalte</h3>
                    <div className="content-cards">
                        <div onClick={() => navigate("../../collaboration/planning")} className="card clickable">
                            <img src={planningImage} alt="" />
                        </div>
                        <div onClick={() => navigate("../../collaboration/construction")} className="card clickable">
                            <img src={constructionImage} alt="" />
                        </div>
                        <div onClick={() => navigate("../../collaboration/simulation")} className="card clickable">
                            <img src={simulationImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};