import { ReactElement, useState } from "react";
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SubGraphProps, MovePointState } from "./Graphs";


export const ArrowGraph = (props: SubGraphProps): ReactElement => {

    const [indexToEdit, setindexToEdit] = useState<MovePointState | undefined>(undefined);
    const [reRenderKey, setReRenderKey] = useState<number>(0);


    const barData = () => {
        let res: any[] = [];
        let bar1: any = { name: "-" }
        for (let i = 0; i < props.data.length; i++) {
            let varr1 = props.data[i].uuid;
            bar1[varr1] = props.data[i].points[0].value;
            bar1["desc"] = props.data[i].title;
        }
        res.push(bar1);
        return res;
    }

    const onPointMouseUp = () => {
        setindexToEdit(undefined);
        reRenderKey >= 100 ? setReRenderKey(0) : setReRenderKey(reRenderKey + 1);
    }

    const onPointMouseDown = (identifier: string) => {
        setindexToEdit({ index: 0, identifier: identifier });
    }

    const onPointMove = (e: CategoricalChartState) => {
        if (indexToEdit && indexToEdit.index >= 0 && e && e.chartX) {
            const ele = document.getElementsByClassName("recharts-surface")[0];
            const yaxis = document.getElementsByClassName("recharts-yAxis")[0];
            const width = ele?.getBoundingClientRect().width;
            const test = ele.getBoundingClientRect().x;
            const test2 = yaxis.getBoundingClientRect().x;
            const offset = (test2 - test) + yaxis?.getBoundingClientRect().width;

            if (width && offset) {
                let newData = [...props.data]
                let newLineIndex = newData.findIndex(nd => nd.uuid === indexToEdit.identifier);
                let newPointVal = 0;
                if ((e.chartX - width / 2) <= 0) {
                    newPointVal = ((e.chartX - (offset)) - (width / 2)) / (width / 200);
                } else {
                    newPointVal = ((e.chartX) - (width / 2)) / (width / 200);
                }
                if (newPointVal > 100) {
                    newPointVal = 100;
                }
                if (newPointVal < -100) {
                    newPointVal = -100;
                }
                if (newLineIndex >= 0) {
                    newData[newLineIndex].points[indexToEdit.index].value = newPointVal;
                    props.updateData(newData);
                }
            }
        }
    }

    const renderArrow = (props: any, id: string) => {
        const { fill, x, y, width, value } = props;
        const ax = x + width + (value > 0 ? -20 : 20);

        return <g>
            <line x1={x} y1={y} x2={x + width + (value > 0 ? -20 : 20)} y2={y} stroke={fill}
                strokeWidth="8" />
            <polygon fill={fill} points={(ax) + " " + (y - 10) + ", " + (ax + (value > 0 ? 10 : -10)) + " " + (y) + ", " + (ax) + " " + (y + 10)} onMouseDown={() => onPointMouseDown(id)} />
        </g>
    }

    const renderBar = () => {
        return props.data.map((l, i) => <Bar
            key={l.uuid}
            dataKey={l.uuid}
            shape={(p: any) => renderArrow(p, l.uuid)}
            fill={l.color}>
        </Bar>
        )
    }

    return <ResponsiveContainer>
        <BarChart
            ref={props.innerRef}
            data={barData()}
            layout="vertical"
            onMouseMove={(e) => onPointMove(e)}
            onMouseUp={() => onPointMouseUp()}
            onMouseLeave={() => onPointMouseUp()}
        >
            <XAxis type="number" interval={0} domain={[-100, 100]} />
            <YAxis type="category" dataKey={"name"} />
            <ReferenceLine x={0} stroke="#000" />
            {renderBar()}
        </BarChart>
    </ResponsiveContainer>

}
