import { ReactElement, useEffect, useState } from "react";
import { getCurrentUserId } from "../../utils/Auth";
import { CircalindApi } from "../circalindApi";
import { Question, SubArea, TableData } from "../dataTypes/generated";
import { assertNever } from "../projects/phases/prePhase/synchro/util";
import { InfoSubArea, infoSubAreaFallback } from "./InfoData";

import './InfoCard.scss';
import { GeneralContent } from "../contentManager/GeneralContent";

export type Props = {
  editForUser?: number,
  subAreaName: InfoSubArea,
  phase: number
}

export const InfoCard = ({ editForUser, subAreaName, phase }: Props): ReactElement => {
  const recipient = editForUser ?? getCurrentUserId("token-circalind");
  const [question, setQuestion] = useState<QuestionState>({ kind: "loading" });
  const [reloadTrigger, setReloadTrigger] = useState<{}>({});

  useEffect(() => {
    if (recipient !== null) {
      return loadQuestion(subAreaName, phase, recipient, setQuestion)
    }
  }, [subAreaName, phase, recipient, reloadTrigger])

  let content: ReactElement | null = null;

  const create = () => {
    switch (question.kind) {
      case 'loading': return;
      case 'loaded': return;
      case 'creating': return;
      case 'no-question':
        createNewQuestion(question.subArea, phase).then(() => setReloadTrigger({}));
        setQuestion({ kind: "creating" });
        return;
      case 'no-area':
        if (recipient !== null) {
          createNewSubArea(recipient, subAreaName).then(subAreaId =>
            createNewQuestion(subAreaId, phase).then(() =>
              setReloadTrigger({})));
          setQuestion({ kind: "creating" });
        }
        return;

      default: assertNever(question);
    }
  }

  const updateQuestion = (td: TableData): void => {
    if (question.kind === "loaded") {
      setQuestion({ ...question, question: { ...question.question, table: td } });
    }
  }

  const fallback = editForUser === undefined
    ? <div>{infoSubAreaFallback[subAreaName]}</div>
    : <div>
      Keine Nutzerspezifischen Informationen hinterlegt.
      <button onClick={create}>Neu Erstellen</button>
    </div>;


  switch (question.kind) {
    case 'loading': content = <div>Laden...</div>; break;
    case 'no-question': content = fallback; break
    case 'no-area': content = fallback; break;
    case 'creating': content = <div>Wird erstellt...</div>; break
    case 'loaded': {
      const edit = editForUser !== undefined;
      content = <div>
        <GeneralContent
          canEdit={edit}
          canReview={false}
          showControls={edit}
          question={question.question}
          onUpdate={updateQuestion}
        />
      </div>;
      break;
    }
    default: assertNever(question);
  }

  return <div className="info-card">
    {content}
  </div>;
}

const loadQuestion = (subAreaName: InfoSubArea, phase: number, recipient: number, setQuestion: (s: QuestionState) => void): () => void => {
  let cancelled = false;

  const run = async () => {
    const subArea = (await CircalindApi.getSubAreas({
      groupId: -1,
      area: "info",
      targetProjectId: null,
      targetUserId: recipient,
      targetInternalGroupId: null,
    })).find(sa => sa.name === subAreaName);
    if (!subArea) {
      setQuestion({ kind: "no-area" });
      return;
    } else {
      const question = await CircalindApi.getContentQuestionByFilterForSubArea({
        subArea: subArea.id,
        notes: false,
        phase: phase,
        userId: recipient,
        order: -1
      });

      if (!cancelled) {
        setQuestion(question
          ? { kind: "loaded", question }
          : { kind: "no-question", subArea: subArea.id }
        );
      }
    }
  }

  run();

  return () => { cancelled = true; }
}

const createNewSubArea = async (recipient: number, name: string): Promise<number> => {
  const empty: SubArea = {
    id: -1,
    groupId: -1,
    area: "info",
    targetUserId: recipient,
    targetProjectId: -1,
    targetInternalGroupId: -1,
    ord: -1,
    name: name,
    image: "",
    color: ""
  }

  return await CircalindApi.addOrUpdateSubArea(empty);
}

const createNewQuestion = async (subAreaId: number, phase: number): Promise<number> => {
  const empty: Question = {
    compass: "NONE",
    graph: { description: "", title: "", type: "NONE", values: [] },
    id: -1,
    kind: "Question",
    order: -1,
    phase: phase,
    group: -1,
    title: "",
    subArea: subAreaId,
    notes: false,
    editedOn: "",
    owner: -1,
    reviewedBy: -1,
    reviewedOn: "",
    version: "1.0",
    table: { cols: 1, rows: 1, tableCells: [], title: "", style: null }

  }

  return await CircalindApi.addOrUpdateQuestion(empty);
}



type QuestionState =
  { kind: "loading" }
  | { kind: "loaded", question: Question }
  | { kind: "no-area" }
  | { kind: "no-question", subArea: number }
  | { kind: "creating" };;
