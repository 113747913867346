import { ReactElement, useMemo, useState } from "react";
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SubGraphProps, MovePointState } from "./Graphs";

export const RadarGraph = (props: SubGraphProps): ReactElement => {

    const [indexToEdit, setindexToEdit] = useState<MovePointState | undefined>(undefined);

    const lineData = () => {
        let res: any[] = [];
        for (let i = 0; i < props.nrOfPoints; i++) {
            let t = props.data.flatMap(l => l.points).filter(k => k.index === i);
            let point: any = { index: i };
            t.forEach(p => {
                point["description"] = p.description;
                let varr = p.name;
                point[varr] = p.value;
            })
            res.push(point);
        }
        return res;
    }

    const onPointMouseUp = () => {
        setindexToEdit(undefined);
    }

    const onPointMouseDown = (e: any, identifier: string) => {
        setindexToEdit({ index: e.index, identifier: identifier });
    }

    const onPointMove = (e: CategoricalChartState) => {
        if (
            indexToEdit
            && e.activeCoordinate !== undefined
            && e.activeCoordinate.radius !== undefined
            && e.activeCoordinate.outerRadius !== undefined
            && e.activeCoordinate.angle !== undefined
        ) {
            let data = [...props.data]
            let valuesIndex = data.findIndex(nd => nd.title === indexToEdit.identifier);
            if (valuesIndex >= 0) {
                let pointIndex = data[valuesIndex].points.findIndex(np => np.index === indexToEdit.index);
                if (pointIndex >= 0) {
                    const r = Math.min(e.activeCoordinate.outerRadius, e.activeCoordinate.radius);
                    const cur = r / e.activeCoordinate.outerRadius;
                    const newPointVal = Math.min(100, cur * 100);
                    const indexToAngles = [90, 18, -54, -126, -198];
                    const validAngles = [-1, 0, 1].map(offset => indexToAngles[(pointIndex + offset + indexToAngles.length) % indexToAngles.length]);
                    const tooFarOff = !validAngles.includes(e.activeCoordinate.angle);
                    data[valuesIndex].points[pointIndex].value = tooFarOff ? 0 : newPointVal;
                    props.updateData(data);
                }

            }
        }
    }

    const renderRadar = () => {
        return props.data.map((l, i) => <Radar key={i}
            activeDot={{ onMouseDown: (_, payload) => onPointMouseDown(payload, l.title), r: 10 }}
            dot={true}
            name="Mike"
            dataKey={l.uuid}
            stroke="#8884d8"
            fill={l.color}
            fillOpacity={0.7}
            isAnimationActive={false}
        />
        )
    };

    // stops flickering of tooltips
    // see: https://github.com/recharts/recharts/issues/300
    // It is not really clear why it is happening or why the issues was closed
    // People seem to still experience it
    const data = lineData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cachedData = useMemo(() => data, [JSON.stringify(data)]);

    return <ResponsiveContainer width="100%" height="100%">
        <RadarChart id="radar-chart-id-comp" cx="50%" cy="50%" outerRadius="90%" data={cachedData} ref={props.innerRef}
            onMouseMove={(e) => onPointMove(e)}
            onMouseUp={() => onPointMouseUp()}
            onMouseLeave={() => onPointMouseUp()}>
            <PolarGrid />
            <Tooltip content={renderTooltip} />
            <PolarAngleAxis dataKey="description" />
            <PolarRadiusAxis domain={[0, 100]} />
            {renderRadar()}
        </RadarChart>
    </ResponsiveContainer>
}
const renderTooltip = () => {
    return undefined;
}

