import { ReactElement, useState } from "react";
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { SubGraphProps, MovePointState } from "./Graphs";


export const LineGraph = (props: SubGraphProps): ReactElement => {

    const [indexToEdit, setindexToEdit] = useState<MovePointState | undefined>(undefined);

    const lineData = () => {
        let res: any[] = [];
        for (let i = 0; i < props.nrOfPoints; i++) {
            let t = props.data.flatMap(l => l.points).filter(k => k.index === i);
            let point: any = { index: i };
            t.forEach(p => {
                point["description"] = p.description;
                let varr = p.name;
                point[varr] = p.value;
            })
            res.push(point);
        }
        return res;
    }

    const onPointMouseUp = () => {
        setindexToEdit(undefined);
    }

    const onPointMouseDown = (e: any, identifier: string) => {
        setindexToEdit({ index: e.index, identifier: identifier });
    }

    const onPointMove = (e: CategoricalChartState) => {
        if (indexToEdit && indexToEdit.index >= 0 && e.chartY) {
            const ele = document.getElementsByClassName("recharts-cartesian-grid")[0];
            const height = ele?.getBoundingClientRect().height;

            if (height) {
                let newData = [...props.data]
                let newPointVal = 100 + ((e.chartY * (-100)) / height);
                if (newPointVal > 100) {
                    newPointVal = 100;
                }
                if (newPointVal < 0) {
                    newPointVal = 0;
                }
                let newLineIndex = newData.findIndex(nd => nd.uuid === indexToEdit.identifier);
                if (newLineIndex >= 0) {
                    let newPointIndex = newData[newLineIndex].points.findIndex(np => np.index === indexToEdit.index);
                    if (newLineIndex >= 0) {
                        newData[newLineIndex].points[newPointIndex].value = newPointVal;
                        props.updateData(newData);
                    }
                }
            }
        }
    }

    const renderTooltip = () => {
        return undefined;
    }

    const renderLine = () => {
        return props.data.map((l, i) => <Line key={i} dataKey={l.uuid} stroke={l.color} strokeWidth={2}
            activeDot={{
                onMouseDown: (event, payload) => onPointMouseDown(payload, l.uuid),
                r: 10
            }} />
        )
    }

    return <ResponsiveContainer width="100%" height="100%">
        <LineChart
            ref={props.innerRef}
            onMouseMove={(e) => onPointMove(e)}
            onMouseUp={() => onPointMouseUp()}
            onMouseLeave={() => onPointMouseUp()}
            width={800}
            height={300}
            data={lineData()}
            margin={{
                top: 5,
                right: 30,
                left: 0,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="description" />
            <YAxis type="number" domain={[0, 100]} />
            <Tooltip content={renderTooltip} />
            <ReferenceLine />

            {renderLine()}
        </LineChart>
    </ResponsiveContainer>

}