import { useEffect, useState } from "react";
import { GraphImage, GraphImageFor, User } from "../../dataTypes/generated";
import { PhaseDiagram } from "../../projects/competence/PhaseDiagram";
import { CircalindApi } from "../../circalindApi";
import Select from 'react-select';

import { getCurrentUserId } from "../../../utils/Auth";
import { UserSelect } from "../../../leading/dialogs/UserSelect";

import "./PhaseDiagramsCompare.scss"

export const PhaseDiagramsCompare = ({ projectId, graphForChoice, leftGraphForChoice, hidePhases }: { projectId?: number, graphForChoice: GraphImageFor, leftGraphForChoice?: GraphImageFor, hidePhases?: Array<number> }) => {
    const [allGraphs, setAllGraphs] = useState<Array<GraphImage>>([]);
    const [users, setUsers] = useState<Array<User>>([]);
    const [selectedAuthor, setSelectedAuthor] = useState<number>();

    const userId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const loadUsers = async () => {
            setUsers((await CircalindApi.getAllUsersOfMyGroup()).filter(u => u.roles.includes("IMPULSE_PARTNER")));
        };

        loadUsers();
    }, []);

    useEffect(() => {
        const loadGraphs = async () => {
            if (userId) {
                const phases = [-1, 1, 2, 3, 4, 5].filter(p => !(hidePhases || []).includes(p));
                let graphs: Array<GraphImage> = [];

                for (const p of phases) {
                    if (leftGraphForChoice) {
                        graphs = [...graphs, ...await CircalindApi.listGraphImages(userId, projectId ? projectId : null, p, graphForChoice), ...await CircalindApi.listGraphImages(userId, projectId ? projectId : null, p, leftGraphForChoice)];
                    } else {
                        graphs = [...graphs, ...await CircalindApi.listGraphImages(userId, projectId ? projectId : null, p, graphForChoice)];
                    }
                }

                setAllGraphs(graphs);
            }
        };

        loadGraphs();
    }, [graphForChoice, hidePhases, leftGraphForChoice, projectId, userId]);


    const renderExistingGraphs = () => {
        const leftGraphs = selectedAuthor ? allGraphs.filter(lg => lg.authorId === selectedAuthor) : [];
        const rightGraphs = allGraphs.filter(lg => lg.authorId === userId);
        const phases = [...new Set([...leftGraphs.map(lg => lg.phase), ...rightGraphs.map(rg => rg.phase)])].sort();

        return <div className="compare-graphs">
            <div className="graph-row">
                <div>
                    <h3>Fremd-Wahrnehmung</h3>
                    <div>
                        <UserSelect placeholder="Impulsgeber wählen" useName={true} users={users} onSelect={u => setSelectedAuthor(u?.id)} valueUserId={selectedAuthor} />
                    </div>
                    <div className="graph-select">
                    </div>
                </div>
                <div><h3>Phase</h3></div>
                <div>
                    <h3>Selbst-Wahrnehmung</h3>
                </div>
            </div>
            {phases.map((p) => {
                const leftGraphsPerPhase = leftGraphs.filter(lg => lg.phase === p);
                const rightGraphsPerPhase = rightGraphs.filter(lg => lg.phase === p);
                return <PhaseRow leftGraphs={leftGraphsPerPhase} rightGraphs={rightGraphsPerPhase} phase={p}></PhaseRow>
            })}
        </div>
    };

    return <div className="phase-compare-diagrams">
        {renderExistingGraphs()}
    </div>
};


const PhaseRow = ({leftGraphs, rightGraphs, phase}: {leftGraphs: Array<GraphImage>, rightGraphs: Array<GraphImage>, phase: number}) => {
    const [leftGraph, setLeftGraph] = useState<GraphImage | undefined>(leftGraphs[0]);
    const [rightGraph, setRightGraph] = useState<GraphImage | undefined>(rightGraphs[0]);

    useEffect(() => {
        setLeftGraph(leftGraphs[0]);
        setRightGraph(rightGraphs[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify([leftGraphs, rightGraphs])])

    const leftGraphOptions = leftGraphs.map(g => {
        return {
            value: g,
            label: g.name || "<Kein Name>"
        };
    })

    const rightGraphOptions = rightGraphs.map(g => {
        return {
            value: g,
            label: g.name || "<Kein Name>"
        };
    })

    return <div key={phase} className="graph-row">
        <div>
            <Select
                placeholder="Diagramm wählen"
                options={leftGraphOptions}
                value={leftGraph && leftGraphOptions.find(lg => lg.value.id === leftGraph.id)}
                onChange={o => setLeftGraph(o?.value)}
            />
            {leftGraph && <PhaseDiagram showTitle={false} edit={false} value={leftGraph} updateGraphImage={() => { }} />}
        </div>
        <div>
            <strong>{phase !== -1 ? <span>Phase {phase}</span> : <span>Phasenübergreifend</span>}</strong>
        </div>
        <div>
            <Select
                placeholder="Diagramm wählen"
                options={rightGraphOptions}
                value={rightGraph && rightGraphOptions.find(lg => lg.value.id === rightGraph.id)}
                onChange={o => setRightGraph(o?.value)}
            />
            {rightGraph && <PhaseDiagram showTitle={false} edit={false} value={rightGraph} updateGraphImage={() => { }} />}
        </div>
    </div>
};