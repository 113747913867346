import { AreaAndRoleInfo, Project, RoleType } from "../dataTypes/generated";

import './projects.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrentUserId } from "../../utils/Auth";
import { DetailAction } from "./ProjectsDetailsView";
import { SynchroCircle } from "./phases/prePhase/synchro/SynchroCircle";
import { defaultConfig } from "./phases/prePhase/synchro/Config";
import { useSelectedProject } from "../../utils/Hooks";
import { useNavigate } from "react-router";

export const renderPhaseStateSVG = (phase: number) => {
    return <svg
        className="clickable"
        viewBox="0 0 400 120">
        <path
            d="m 76.0029,64.5513 -16.323,46.313 c -0.641,1.819 -2.359,3.035 -4.288,3.036 l -48.348,0.016 c -3.133,10e-4 -5.329,-3.091 -4.295,-6.048 l 15.143,-43.338 c 0.85,-2.433 0.848,-5.083 -0.005,-7.515 L 2.7599,13.9103 c -1.038,-2.959 1.159,-6.056 4.294,-6.054 l 48.423,0.025 c 1.929,10e-4 3.647,1.218 4.287,3.037 l 16.239,46.074 c 0.862,2.446 0.862,5.113 0,7.559"
            fill={phase >= 1 ? "#005c9a" : "none"} stroke="#005c9a" strokeWidth="6"
            id="path24" />
        <path
            transform="translate(70)"
            d="m 76.0029,64.5513 -16.323,46.313 c -0.641,1.819 -2.359,3.035 -4.288,3.036 l -48.348,0.016 c -3.133,10e-4 -5.329,-3.091 -4.295,-6.048 l 15.143,-43.338 c 0.85,-2.433 0.848,-5.083 -0.005,-7.515 L 2.7599,13.9103 c -1.038,-2.959 1.159,-6.056 4.294,-6.054 l 48.423,0.025 c 1.929,10e-4 3.647,1.218 4.287,3.037 l 16.239,46.074 c 0.862,2.446 0.862,5.113 0,7.559"
            fill={phase >= 2 ? "#005c9a" : "none"} stroke="#005c9a" strokeWidth="6"
            id="path24" />
        <path
            transform="translate(140)"
            d="m 76.0029,64.5513 -16.323,46.313 c -0.641,1.819 -2.359,3.035 -4.288,3.036 l -48.348,0.016 c -3.133,10e-4 -5.329,-3.091 -4.295,-6.048 l 15.143,-43.338 c 0.85,-2.433 0.848,-5.083 -0.005,-7.515 L 2.7599,13.9103 c -1.038,-2.959 1.159,-6.056 4.294,-6.054 l 48.423,0.025 c 1.929,10e-4 3.647,1.218 4.287,3.037 l 16.239,46.074 c 0.862,2.446 0.862,5.113 0,7.559"
            fill={phase >= 3 ? "#005c9a" : "none"} stroke="#005c9a" strokeWidth="6"
            id="path24" />
        <path
            transform="translate(210)"
            d="m 76.0029,64.5513 -16.323,46.313 c -0.641,1.819 -2.359,3.035 -4.288,3.036 l -48.348,0.016 c -3.133,10e-4 -5.329,-3.091 -4.295,-6.048 l 15.143,-43.338 c 0.85,-2.433 0.848,-5.083 -0.005,-7.515 L 2.7599,13.9103 c -1.038,-2.959 1.159,-6.056 4.294,-6.054 l 48.423,0.025 c 1.929,10e-4 3.647,1.218 4.287,3.037 l 16.239,46.074 c 0.862,2.446 0.862,5.113 0,7.559"
            fill={phase >= 4 ? "#005c9a" : "none"} stroke="#005c9a" strokeWidth="6"
            id="path24" />
        <path
            transform="translate(280)"
            d="m 76.0029,64.5513 -16.323,46.313 c -0.641,1.819 -2.359,3.035 -4.288,3.036 l -48.348,0.016 c -3.133,10e-4 -5.329,-3.091 -4.295,-6.048 l 15.143,-43.338 c 0.85,-2.433 0.848,-5.083 -0.005,-7.515 L 2.7599,13.9103 c -1.038,-2.959 1.159,-6.056 4.294,-6.054 l 48.423,0.025 c 1.929,10e-4 3.647,1.218 4.287,3.037 l 16.239,46.074 c 0.862,2.446 0.862,5.113 0,7.559"
            fill={phase >= 5 ? "#005c9a" : "none"} stroke="#005c9a" strokeWidth="6"
            id="path24" />
    </svg>
}

export interface ProjectDetailsProps {
    p: Project;
    areaAndRoleInfo: AreaAndRoleInfo | null;
    actions: Array<DetailAction>;
    actionHeading: string;
}

export const ProjectDetail = (props: ProjectDetailsProps) => {
    const userId = getCurrentUserId("token-circalind");
    const [, setProject] = useSelectedProject();
    const navigate = useNavigate();

    const getProjectTypeString = () => {
        switch (props.p.type) {
            case "SHARED": return "Führung- und Zusammenarbeit";
            case "STRATEGIC": return "Strategie-Projekt";
            default: return "Persönliches Projekt";
        }
    }

    const getRoleText = () => {
        const roles = props.p.members.filter(m => m.user.id === userId).map(m => props.areaAndRoleInfo ? props.areaAndRoleInfo.roleTitles[m.role] : m.role);

        if (props.p.ownerId === userId) {
            const decider: RoleType = "DECIDER";
            roles.push(props.areaAndRoleInfo ? props.areaAndRoleInfo.roleTitles[decider] : decider);
        }

        return roles.join(", ");
    };

    const renderActions = () => {
        return props.actions.map((a, i) => {
            if (a.icon) {
                return <div key={i} className="action">
                    <span>{a.tooltip}</span>
                    <FontAwesomeIcon className="action-image" icon={a.icon} onClick={(e) => { e.preventDefault(); e.stopPropagation(); a.action(props.p) }} />
                </div>
            } else {
                return <div key={i} className="action">
                    <span>{a.tooltip}</span>
                    <img className="action-image" src={a.image} alt={a.tooltip} onClick={(e) => { e.preventDefault(); e.stopPropagation(); a.action(props.p) }} />
                </div>
            }
        });
    };

    return <div className="project-detail-wrapper" key={props.p.id}>
        <div className="project-detail-card">
            <span className="date">{"Projektbeginn: " + props.p.date}</span>
            <div className="row-detail">
                <div className="top-title">{"Einsatzbereich: " + getProjectTypeString()}</div>
                <div className="top-name"><strong>{props.p.importance} - {props.p.name}</strong></div>
            </div>
            <div className="row-detail">
                <svg className="clickable" onClick={() => navigate("/circalind/regular/dashboard/families")} viewBox="-15 -15 30 30" width="100" height="100">
                    <SynchroCircle showIs={true} project={props.p} config={{ ...defaultConfig, showText: false, valueWidth: 1.5, projectRadius: 8 }} onClick={() => { }} onDragStart={() => { }} onDragEnd={() => { }} onSetValue={() => { }} />
                </svg>
                <div className="quantity"><span>{"Assistenzteam: " + new Set([...props.p.members.map(m => m.user.id), props.p.ownerId]).size}</span></div>
            </div>
            <div className="row-detail">
                <div onClick={() => { setProject(props.p.id); navigate("/circalind/regular/dashboard/base/start") }} className="phase-state">
                    <p>{props.p && props.p.phase > 0 ? "Projektbasis aktualisieren" : "Projektbasis eingeben"}</p>
                    {renderPhaseStateSVG(props.p.phase)}
                </div>
                <div className="responsible">
                    <div>Meine Rollen im Projekt:</div>
                    {getRoleText()}
                    <div className="actions">
                        <h4>{props.actionHeading}</h4>
                        <div className="action-items">
                            {renderActions()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
}
