import { ReactElement, useEffect, useState } from "react";
import { CircalindApi } from "../circalindApi";
import { AreaAndRoleInfo, CircaPackage, Project, User } from "../dataTypes/generated";
import { ProjectDetail } from "./Projects";
import { EditStaffingProject } from "../../leading/dialogs/EditStaffingProject";
import { Dialog } from "../basicComponents/dialogs/Dialog";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import circalindManagementImage from "../../resources/circa/circalind_managing.svg";

import './projects.scss'
import { useNavigate } from "react-router";

export interface DetailAction {
    action: (p: Project) => Promise<void>;
    tooltip: string;
    icon?: IconProp;
    image?: string;
}

export interface ProjectDetailsProps {
    projects: Project[];
    actionHeading: string;
    actions: Array<DetailAction>;
    heading: string;
}

export const ProjectsDetailsView = (props: ProjectDetailsProps): ReactElement => {
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);
    const [users, setUsers] = useState<Array<User>>([]);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [license, setLicenseAndProject] = useState<CircaPackage | null>(null);
    const [showNewProjectDialog, setShowNewProjectDialog] = useState<boolean>(false);

    const navigate = useNavigate();

    const getAreaAndRoleInfo = async () => {
        const res = await CircalindApi.getAreaAndRoleInfo();
        setAreaAndRoleInfo(res);
    }

    const getLicense = async () => {
        const res = await CircalindApi.getLicense();
        setLicenseAndProject(res);
    }

    const getUsers = async () => {
        const res = await CircalindApi.getAllUsersOfMyGroup();
        setUsers(res);
    }


    useEffect(() => {
        getAreaAndRoleInfo();
        getLicense();
        getUsers();
    }, []);

    return <div>
        <div className="new-button">
            <img onClick={() => navigate("../")} alt="circalind managen" src={circalindManagementImage} />
            <h2>{props.heading}</h2>
            <button onClick={() => { setSelectedProject(undefined); setShowNewProjectDialog(true) }} className="project-detail-card">Neues Projekt</button>
        </div>
        <div className="projects-columns">
            <div className="projects-list">
                <h3>Persönliche Projekte</h3>
                {props.projects.filter(p => p.type === "PERSONAL").sort((a, b) => a.date > b.date ? -1 : 1).map(p => <div key={p.id} onClick={() => { setSelectedProject(p); setShowNewProjectDialog(true) }}><ProjectDetail p={p} areaAndRoleInfo={areaAndRoleInfo} actions={props.actions} actionHeading={props.actionHeading} /></div>)}
            </div>
            <div className="projects-list">
                <h3>Strategie Projekte</h3>
                {props.projects.filter(p => p.type === "STRATEGIC").sort((a, b) => a.date > b.date ? -1 : 1).map(p => <div key={p.id} onClick={() => { setSelectedProject(p); setShowNewProjectDialog(true) }}><ProjectDetail p={p} areaAndRoleInfo={areaAndRoleInfo} actions={props.actions} actionHeading={props.actionHeading} /></div>)}
            </div>
            <Dialog className="editStaffing" show={showNewProjectDialog} toogle={() => setShowNewProjectDialog(false)} component={<EditStaffingProject showAllDeciders={false} showData={true} showRoles={false} selected={selectedProject} users={users} onUpdate={() => window.location.reload()} onCancel={() => setShowNewProjectDialog(false)} licenseType={license?.type || null} />} />
        </div>
    </div>
}