import { QuestionArea } from "../circalind/dataTypes/generated";

export const getQuestionAreaText = (value: QuestionArea) => {
    switch (value) {
        case "CONTEXT":
            return "Kontext";
        case "DRIVERS":
            return "Treiber";
        case "HORIZON":
            return "Ziel-zeit-Horizonte";
        case "RESOURCE":
            return "Ressourcen-Status";
        case "COMPETENCE":
            return "Kompetenz-Struktur";
        case "FEEDBACK":
            return "Feedback";
        case "SYSTEM":
            return "akteure-/ Staekholder-System";
        case "VALUESGOALS":
            return "Werte- & Ziel-Pyramide";
        default:
            return "ERROR MISSING"
    }
}

export enum Phases {
    PHASE_1 = "Anliegen - Ziel formulieren",
    PHASE_2 = "Lage Beschreiben",
    PHASE_3 = "Lage Bewerten",
    PHASE_4 = "Entscheidungen treffen",
    PHASE_5 = "Vorhaben umsetzen",
}

export const degrees_to_radians = (degrees: number): number => {
    return degrees * (Math.PI / 180);
}

export const validMail = (mail: string): boolean => {
    const regexp = new RegExp(/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
    return regexp.test(mail)
}
