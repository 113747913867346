import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactElement } from "react";
import { Graph, GraphValues } from "../../../dataTypes/generated";
import { LineGraph } from "./LineGraph";
import { PieGraph } from "./PieGraph";
import { RadarGraph } from "./RadarGraph";
import { v4 as uuidv4 } from 'uuid';
import { BarGraph } from "./BarGraph";
import { SquareGraph } from "./SqareGraph";
import { ArrowGraph } from "./ArrowGraph";

export interface graphData {
    name: string;
    uv: number;
    pv: number;
    amt: number
}

export interface SubGraphProps {
    data: GraphValues[];
    nrOfPoints: number;
    updateData: (data: GraphValues[]) => void;
    innerRef?: React.MutableRefObject<any>
}

export interface MovePointState {
    identifier: string;
    index: number;
}

export interface GraphProps {
    graph: Graph;
    edit: boolean;
    showLegend: boolean;
    userCanAddLines?: boolean;
    nrOfPoints: number;
    onGraphUpdate: (values: GraphValues[]) => void;
    updateGraphPNG?: (data: string) => Promise<void>;
    innerRef?: React.MutableRefObject<any>
    onTitleChange?: (title: string) => void;
    dissablePointDesc?: boolean;
}

export const handleGetGraphPNG = async (fn: () => Promise<string>) => {
    const png = await fn();
    if (png) {
        return png;
    }
    return "";
};

export const createEmptyPointsForGraphValues = (graphValue: GraphValues, count: number, defaultValue?: number) => {
    for (let i = 0; i < count; i++) {
        graphValue.points.push({
            name: graphValue.uuid,
            description: "" + i,
            index: i,
            value: defaultValue ? defaultValue : 0
        })
    }
}


export const createEmptyPointsForGraphValuesWithDesc = (graphValue: GraphValues, descriptions: string[], defaultValue?: number) => {
    graphValue.points = [];
    for (let i = 0; i < descriptions.length; i++) {
        graphValue.points.push({
            name: graphValue.uuid,
            description: descriptions[i],
            index: i,
            value: defaultValue ? defaultValue : 0
        })
    }
}

export const Graphs = (props: GraphProps): ReactElement => {

    const addNewLine = () => {
        let newGraphData = [...props.graph.values];
        if (newGraphData.length > 0) {
            let copy: GraphValues = JSON.parse(JSON.stringify(newGraphData[newGraphData.length - 1]))
            copy.title = "new-" + newGraphData.length;
            copy.uuid = uuidv4();
            copy.color = "#" + Math.floor(Math.random() * 16777215).toString(16);
            copy.points.forEach(p => {
                p.name = copy.uuid;
                p.value = 15;
            })
            newGraphData.push(copy);
        } else {
            let newGraphValue: GraphValues = {
                uuid: uuidv4(),
                title: "new-" + newGraphData.length,
                color: "#" + Math.floor(Math.random() * 16777215).toString(16),
                numberOfPoints: 5,
                points: []
            }
            createEmptyPointsForGraphValues(newGraphValue, 5, 25);
            newGraphData.push(newGraphValue);
        }
        props.onGraphUpdate(newGraphData);
    }

    const updateLineColorData = (color: string, index: number) => {
        let newGraphData = [...props.graph.values];
        newGraphData[index].color = color;
        props.onGraphUpdate(newGraphData);
    }

    const updateLineNameData = (newTitle: string, index: number) => {
        let newGraphData = [...props.graph.values];
        newGraphData[index].title = newTitle;
        props.onGraphUpdate(newGraphData);
    }

    const deleteLine = (index: number) => {
        let newGraphData = [...props.graph.values];
        newGraphData.splice(index, 1);
        props.onGraphUpdate(newGraphData);
    }

    const renderGraphByType = () => {
        switch (props.graph.type) {
            case "LINE": return <LineGraph innerRef={props.innerRef} data={props.graph.values} nrOfPoints={5} updateData={(d) => props.edit ? props.onGraphUpdate(d) : null} />
            case "RADAR": return <RadarGraph innerRef={props.innerRef} data={props.graph.values} nrOfPoints={5} updateData={(d) => props.edit ? props.onGraphUpdate(d) : null} />
            case "PIE": return <PieGraph innerRef={props.innerRef} data={props.graph.values} nrOfPoints={5} updateData={(d) => props.edit ? props.onGraphUpdate(d) : null} />
            case "BAR": return <BarGraph innerRef={props.innerRef} data={props.graph.values} nrOfPoints={5} updateData={(d) => props.edit ? props.onGraphUpdate(d) : null} />
            case "SQARES": return <SquareGraph innerRef={props.innerRef} data={props.graph.values} nrOfPoints={5} updateData={(d) => props.edit ? props.onGraphUpdate(d) : null} />
            case "ARROWS": return <ArrowGraph innerRef={props.innerRef} data={props.graph.values} nrOfPoints={5} updateData={(d) => props.edit ? props.onGraphUpdate(d) : null} />
            default: return <span>{"ERROR, MISSING COMPONENT FOR TYPE " + props.graph.type}</span>
        }
    }

    const updatePointDescription = (index: number, newValue: string) => {
        let newData = [...props.graph.values];
        newData.forEach(l => {
            l.points[index].description = newValue;
        })
        props.onGraphUpdate(newData);
    }

    const renderPointDescritpions = () => {
        if (props.graph.type !== "BAR") {
            return <div className="point-legend">
                <span>Beschriftungen:</span>
                {props.graph.values[0].points.map((p, i) => <li key={i}>
                    <input disabled={props.dissablePointDesc ? props.dissablePointDesc : false} value={p.description} onChange={(e) => updatePointDescription(i, e.target.value)} />
                </li>)}
            </div>
        }
        return null
    }

    const renderLineLegend = () => {
        if (props.graph.type !== "SQARES" && props.graph.type !== "ARROWS") {
            if (props.edit || props.userCanAddLines === true) {
                return <Fragment>
                    {props.graph.values.map((g, i) => <li key={i}>
                        <input className="color-input" type="color" value={g.color} onChange={(e) => updateLineColorData(e.target.value, i)} />
                        <input className="text-input" value={g.title} onChange={(e) => updateLineNameData(e.target.value, i)} />
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLine(i)} />
                    </li>)}
                    {renderPointDescritpions()}
                </Fragment>
            } else {
                return <Fragment>
                    {props.graph.values.map((g, i) => <li key={i}><div className="color-preview" style={{ backgroundColor: g.color }} />
                        {g.title}
                    </li>)}

                    {renderPointDescritpions()}
                </Fragment>
            }
        } else if (props.graph.type === "ARROWS") {
            if (props.edit || props.userCanAddLines === true) {
                return <Fragment>
                    {props.graph.values.map((g, i) => <li key={i}>
                        <input className="color-input" type="color" value={g.color} onChange={(e) => updateLineColorData(e.target.value, i)} />
                        <input className="text-input" value={g.title} onChange={(e) => updateLineNameData(e.target.value, i)} />
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLine(i)} />
                    </li>)}
                </Fragment>
            } else {
                return <Fragment>
                    {props.graph.values.map((g, i) => <li key={i}><div className="color-preview" style={{ backgroundColor: g.color }} />
                        {g.title}
                    </li>)}
                </Fragment>
            }
        } else {
            if (props.edit || props.userCanAddLines) {
                return <Fragment>
                    {props.graph.values.map((g, i) => <li key={i}>
                        <input className="text-input" value={g.title} onChange={(e) => updateLineNameData(e.target.value, i)} />
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLine(i)} />
                    </li>)}
                </Fragment>
            } else {
                return <Fragment>
                    {props.graph.values.map((g, i) => <li key={i}>
                        {g.title}
                    </li>)}
                </Fragment>
            }
        }
    }

    const renderGraphLegend = () => {
        return <div className="graph-legend">
            <ul>
                {renderLineLegend()}
            </ul>
            {(props.edit || props.userCanAddLines === true) ? <FontAwesomeIcon icon={faPlusCircle} onClick={() => addNewLine()} /> : undefined}
        </div>
    }

    const renderTitleInput = () => {
        if (props.onTitleChange !== undefined) {
            return <input disabled={!props.edit} className="graph-title-input" placeholder="Titel" value={props.graph.title} onChange={(e) =>
                props.onTitleChange!(e.target.value)} />
        } else {
            return <input style={{visibility: "hidden"}} disabled className="graph-title-input" />
        }
    }

    return <div className="graph-items">
        {props.showLegend && renderGraphLegend()}
        <div className="question-graph">
            {renderTitleInput()}
            {renderGraphByType()}
        </div>
    </div>
}