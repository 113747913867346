import { Fragment, ReactElement, useEffect, useState } from "react";
import { CircalindApi } from "../../circalindApi";
import { AreaAndRoleInfo, Group, GroupUser, Poll, PollAnswer, Project, TableData } from "../../dataTypes/generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { CompassTable, userAnswers } from "../../projects/compass/table/CompassTable";
import { TableAnswerCell } from "../../hooks/useAnswer";
import { sendOlert } from "../../../olert/Olert";
import { useSelectedProject } from "../../../utils/Hooks";
import { getCurrentUserId } from "../../../utils/Auth";

import "./polls.scss";

export const Polls = (): ReactElement => {
    const [polls, setPolls] = useState<Array<Poll>>([]);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo>();
    const [projectId,] = useSelectedProject();
    const [currentProject, setCurrentProject] = useState<Project>();
    const [group, setGroup] = useState<Group | null>();

    const userId = getCurrentUserId("token-circalind");

    useEffect(() => {
        const getAreaAndRoleInfo = async () => {
            const res = await CircalindApi.getAreaAndRoleInfo();
            setAreaAndRoleInfo(res);
        };

        const getGroup = async () => {
            const res = await CircalindApi.getMyGroup();
            setGroup(res);
        }

        getAreaAndRoleInfo();
        getGroup();
    }, []);

    useEffect(() => {
        const loadProject = async () => {
            if (projectId !== -1) {
                const res = await CircalindApi.getProject(projectId);
                if (res) {
                    setCurrentProject(res);
                }
            } else {
                setCurrentProject(undefined);
            }
        };

        const getPolls = async () => {
            const res = await CircalindApi.getPollsForProjectId(projectId);
            setPolls(res);
        };

        loadProject();
        getPolls();
    }, [projectId]);

    const deletePoll = async (pollId: number) => {
        if (window.confirm("Umfrage wirklich löschen?")) {
            if (pollId !== -1) {
                const res = await CircalindApi.deletePoll(pollId);
                if (res) {
                    sendOlert("Erfolg", "Umfrage gelöscht.", "Success");
                    setPolls(polls.filter(p => p.id !== pollId));
                }
            } else {
                setPolls(polls.filter(p => p.id !== pollId));
            }
        }
    };


    const onNewPoll = () => {
        if (projectId > -1 && userId) {
            const emptyTable = { cols: 1, rows: 1, title: "Umfrage", tableCells: [], style: null }
            setPolls([{
                id: -1,
                projectId: projectId,
                groupId: null,
                authorId: userId,
                audience: [],
                question: JSON.stringify(emptyTable),
                date: new Date().toISOString(),
            }, ...polls]);
        }
    };

    const updatePoll = (poll: Poll) => {
        const copy = [...polls];
        const index = polls.findIndex(p => p.id === poll.id);
        //new poll update id
        if (index === -1) {
            copy[polls.findIndex(p => p.id === -1)] = poll;
        } else {
            copy[index] = poll;
        }
        setPolls(copy);
    };

    const filterPolls = (poll: Poll) => {
        if (currentProject!.ownerId === userId) {
            return true;
        } else if (currentProject!.members.find(m => m.user.id === userId && poll.audience.includes(m.user.id))) {
            return true;
        } else if (userId === poll.authorId) {
            return true;
        } else {
            return false;
        }
    };

    return <div className="polls">
        <h1>Fragen</h1>
        {!currentProject && <div>Kein Projekt gewählt</div>}
        {areaAndRoleInfo && currentProject &&
            <>
                <div className="polls-header">
                    <button disabled={projectId === -1 || polls.find(p => p.id === -1) !== undefined} onClick={onNewPoll}><FontAwesomeIcon icon={faPlusCircle} /> Neue Frage</button>
                </div>
                {polls.filter(filterPolls).map(poll => group && <PollView group={group} key={poll.id} areaAndRoleInfo={areaAndRoleInfo} poll={poll} updatePoll={updatePoll} deletePoll={deletePoll} project={currentProject} />)}
            </>
        }
    </div>
}

const PollView = ({ poll, group, updatePoll, deletePoll, areaAndRoleInfo, project }: { poll: Poll, group: Group, updatePoll: (poll: Poll) => void, deletePoll: (pollId: number) => void, areaAndRoleInfo: AreaAndRoleInfo, project: Project }) => {
    const [author, setAuthor] = useState<GroupUser>();
    const [answers, setAnswers] = useState<TableAnswerCell[]>([]);
    const [preview, setPreview] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(poll.id === -1);
    const [userAnswers, setUserAnswers] = useState<userAnswers[] | undefined>(undefined);
    const tooglePreview = () => {
        setPreview(!preview);
    }

    useEffect(() => {
        const getAuthor = async () => {
            const res = await CircalindApi.getGroupUserWithoutImage(poll.authorId);
            if (res) {
                setAuthor(res);
            }
        };

        getAuthor();
    }, [poll.authorId]);

    const userId = getCurrentUserId("token-circalind");

    const isTableInEdit = () => {
        if (edit) {
            if (userAnswers !== undefined) {
                return false;
            } else {
                return !preview;
            }
        }
        return false;
    }

    const onCellAnswer = (newData: TableAnswerCell[]) => {
        setAnswers(newData);
    }

    const onUpdate = (newData: TableData, changeCellType?: boolean | undefined) => {
        updatePoll({ ...poll, question: JSON.stringify(newData) });
    }

    const onUserSave = async () => {
        const toSave: PollAnswer = { userId: 0, pollId: poll.id, answer: JSON.stringify(answers) }
        const res = await CircalindApi.savePollAnswer(toSave);
        if (res) {
            sendOlert("Erfolgreich", "gespeichert", "Success");
        } else {
            sendOlert("Fehler", "Antowrt konnte nicht gespeichert werden", "Error");
        }
    }

    const onSave = async () => {
        if (poll.id === -1) {
            const res = await CircalindApi.addPoll(poll);
            if (res) {
                sendOlert("Erfolgreich", "Frage angelegt.", "Success");
                updatePoll({ ...poll, id: res });
            } else {
                sendOlert("Fehler", "Frage konnte nicht gespeichert werden", "Error");
            }
        } else {
            const res = await CircalindApi.updatePoll(poll);
            if (res) {
                sendOlert("Erfolgreich", "Frage gespeichert.", "Success");
            } else {
                sendOlert("Fehler", "Frage konnte nicht gespeichert werden", "Error");
            }
        }
    };

    useEffect(() => {
        const getAnswers = async (groupId: number) => {
            const res = await CircalindApi.getPollAnswer(groupId);
            if (res !== null) {
                let parsed: TableAnswerCell[] = [];
                try {
                    parsed = JSON.parse(res.answer)
                } catch {
                    parsed = [];
                }
                setAnswers(parsed);
            }
        }
        if (poll) {
            getAnswers(poll.id);
        }
    }, [poll]);

    const toggleResults = async () => {
        const loadRawResults = async (id: number) => {
            const res = await CircalindApi.getPollResult(id);

            if (res.length > 0 && group !== null) {

                const getUserData = (userId: number): { mail: string, name: string } => {
                    const u = group.users.find(m => m.id === userId)
                    if (u) {
                        return { mail: u.email, name: u.firstName + " " + u.lastName }
                    }
                    return { mail: "-", name: "Anonym" }
                }

                let parseAllAnswers: userAnswers[] = [];
                res.forEach(r => {
                    const userData = getUserData(r.userId);
                    let userAnswer: userAnswers = { userId: r.userId, userMail: userData.mail, userName: userData.name, answer: { answer: "", column: -1, row: -1 } };
                    try {
                        const answerCells: TableAnswerCell[] = JSON.parse(r.answer);
                        answerCells.forEach(g => {
                            let c: userAnswers = JSON.parse(JSON.stringify(userAnswer));
                            c.answer = g;
                            parseAllAnswers.push(c);
                        })
                    } catch {
                        // do nothing;
                    }
                });
                setUserAnswers(parseAllAnswers);
            }
        }
        if (userAnswers === undefined) {
            if (poll !== null) {
                loadRawResults(poll.id);
            }
        }
        else {
            setUserAnswers(undefined)
        }
    }

    const renderAudience = () => {
        return <div className="audience">
            <strong>Projekt-Assistenzteam: </strong>
            <div className="roles">
                {project.members
                    .filter(m => group.allowedPollRoles.includes(m.role))
                    .map(m => {
                        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.target.checked) {
                                updatePoll({ ...poll, audience: [...poll.audience, m.user.id] });
                            } else {
                                updatePoll({ ...poll, audience: poll.audience.filter(r => r !== m.user.id) });
                            }
                        };
                        return <label key={m.user.id}><input checked={poll.audience.includes(m.user.id)} onChange={onChange} type="checkbox"></input> {m.user.firstName} {m.user.lastName}</label>
                    })}
            </div>
        </div>
    };

    const renderPollHeader = () => {
        return <div className="poll-header">
            <span><strong>Erstellt von: </strong>{author ? `${author.firstName} ${author.lastName}` : "-"}</span><span><strong>Erstellt am: </strong>{poll.date ? new Date(poll.date).toLocaleString() : "-"}</span>
        </div>;
    };

    const allowedToViewResult = group.allowedPollResult === "AUTHOR" ? poll.authorId === userId : true;

    return <div className="poll">
        {project.ownerId === userId && renderPollHeader()}
        {edit ? renderAudience() : null}
        <h3>Frage und Fragetyp definieren</h3>
        <CompassTable
            edit={isTableInEdit()}
            data={JSON.parse(poll.question)}
            answers={answers}
            onCellAnswerd={onCellAnswer}
            onUpdate={onUpdate}
            allowedCellTypes={["BOOLEAN", "INFO", "LINK", "MULTICHOICE", "SINGLECHOICE", "STATICIMAGE", "TEXT", "YOUTUBE"]}
            multipleAnswers={userAnswers}
        />
        {edit ? <div className="edit-tools">
            { }
            {userAnswers === undefined ? <Fragment>
                <button onClick={() => tooglePreview()}>{preview ? "Editieren" : "Vorschau"}</button>
                <button onClick={() => onSave()}>Frage speichern</button>
                <button disabled={poll.id === -1} onClick={() => setEdit(false)}>Frage beantworten</button>
                <button disabled={poll.authorId !== userId} onClick={() => deletePoll(poll.id)}><FontAwesomeIcon icon={faTrashAlt} /></button>
            </Fragment> : null}
        </div>
            :
            <div className="edit-tools">
                <button disabled={poll.id === -1} onClick={() => onUserSave()}>Antworten Speichern</button>
                <button disabled={poll.id === -1 && allowedToViewResult} onClick={() => toggleResults()}>{userAnswers === undefined ? "Ergebnisse" : "Zurück"}</button>
                <button disabled={poll.authorId !== userId} onClick={() => setEdit(true)}>Umfrage bearbeiten</button>
            </div>
        }
    </div>
};