import { ReactElement, useEffect, useState } from "react";
import { convertAndDownload } from "../../../invoice/utils/Util";
import { sendOlert } from "../../../olert/Olert";
import { getActiveGroup, getToken } from "../../../utils/Auth";
import { Dialog } from "../../basicComponents/dialogs/Dialog";
import { CircalindApi } from "../../circalindApi";
import { CircaPackage, DeskItem, DeskType, Group, LicenseTypes, Project, TableCell, TableData } from "../../dataTypes/generated";
import { TableAnswerCell } from "../../hooks/useAnswer";
import { CompassTable } from "../compass/table/CompassTable";
import { PhaseHeader } from "../phases/PhaseHeader";
import { NewDeskItem } from "./NewDeskItem";
import { useSelectedProject } from "../../../utils/Hooks";
import { faDownload, faShare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EmptyProjectImage from "../../../resources/circa/cockpit/project-empty.png";
import mywayImage from "../../../resources/circa/documentation.png";
import deskImage from "../../../resources/circa/desk.png";

import './projectdesk.scss';
import { useNavigate } from "react-router";
import { ProjectSelect } from "../../../leading/dialogs/ProjectSelect";
import { managementPhases } from "../../page/menus/Menu";



export const ProjectDesk = ({ projectSpecific }: { projectSpecific: boolean }): ReactElement => {
    const [projectId,] = useSelectedProject();
    const [project, setProject] = useState<Project>();
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [items, setItems] = useState<DeskItem[]>([]);
    const [newDialog, setNewDialog] = useState<boolean>(false);
    const [phase, setPhase] = useState<number>(-1);
    const [type, setType] = useState<DeskType>("DOCUMENTS");
    const [searchTags, setSearchTags] = useState<Array<string>>([]);
    const [showTagDialog, setShowTagDialog] = useState<boolean>(false);
    const [showShareProjectDialog, setShowShareProjectDialog] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<DeskItem>();
    const [group, setGroup] = useState<Group | null>(null);
    const [license, setLicense] = useState<CircaPackage | null>(null);


    const navigate = useNavigate();

    const groupId = getActiveGroup("token-circalind")!;

    const toggleDialog = () => {
        setNewDialog(!newDialog)
    }

    const updateItems = async () => {
        const res = projectSpecific ? await CircalindApi.getAllDeskItemsForProject(projectId) : await CircalindApi.getAllDeskItemsForGroup(groupId);
        if (res) {
            setItems(res);
        }
    }

    useEffect(() => {
        const loadGroup = async () => {
            const res = await CircalindApi.getMyGroup();
            if (res) {
                setGroup(res);
            }
        }

        const loadProjects = async () => {
            const res = await CircalindApi.getMyProjects();
            if (res) {
                setProjects(res.map(p => p.project));
            }
        }

        loadGroup();
        loadProjects();
    }, [])

    useEffect(() => {
        const loadProject = async (id: number) => {
            const res = await CircalindApi.getProject(id);
            if (res) {
                setProject(res);
            }

            setLicense(await CircalindApi.getLicense());
        }

        const internalUpdate = async () => {
            const res = projectSpecific ? await CircalindApi.getAllDeskItemsForProject(projectId) : await CircalindApi.getAllDeskItemsForGroup(groupId);
            if (res) {
                setItems(res);
            }
        }

        loadProject(projectId);
        internalUpdate();
    }, [groupId, projectId, projectSpecific])

    const onUpdate = (data: TableData) => {
    }

    const onAnswer = (data: TableAnswerCell[]) => {
    }

    const onDelete = async (id: number) => {
        const res = await CircalindApi.deleteDeskItem(id);
        if (res) {
            sendOlert("Erfolgreich", "gelöscht", "Success");
        } else {
            sendOlert("Fehler", "Objekt konnte nicht gelöscht werden", "Error");
        }
        updateItems();
    }

    const getItemAndDownload = async (id: number) => {
        const res = await CircalindApi.getDeskItem(id);
        if (res) {
            convertAndDownload(res.base64Data.split('base64,')[1], res.date!.split("T")[0] + "_" + res.filename);
        }
    }

    const onItemClicked = async (id: number, action: "new" | "delete" | "open" | "tags" | "source", row: number, col: number) => {
        if (action === "new") {
            if (group) {
                setPhase(projectSpecific ? col : col - 1);
            }
            switch (row) {
                case 1: setType("SCREENSHOTS"); break;
                case 2: setType("DOCUMENTS"); break;
                case 3: setType("QUOTE"); break;
                case 4: setType("PHOTOS"); break;
                case 5: setType("PRESENTATION"); break;
                default: setType("DOCUMENTS"); break;
            }
            toggleDialog();
        } else if (action === "tags") {
            setShowTagDialog(true);
            setSelectedItem(items.find(i => i.id === id));
        } else if (action === "delete") {
            onDelete(id);
        } else if (action === "open") {
            getItemAndDownload(id);
        } else if (action === "source") {
            const item = items.find(i => i.id === id);
            if (item && item.source) {
                window.location.href = item.source;
            }
        }
    }

    const getItemsForPhaseAndType = (phase: number, type: DeskType): DeskItem[] => {
        return items.filter(i => i.phase === phase && i.type === type).filter(i => {
            if (searchTags.length > 0) {
                return searchTags.some(st => i.tags.includes(st));
            } else {
                return true;
            }
        });
    }

    const generateData = (): TableData => {
        const generateContentRow = (row: number, type: DeskType): Array<TableCell> => {
            const columns = 6;

            const rowTitle = (() => {
                switch (type) {
                    case "DOCUMENTS":
                        return "Dokumente";
                    case "PHOTOS":
                        return "Fotos";
                    case "QUOTE":
                        return "Zitate";
                    case "PRESENTATION":
                        return "Präsentationen";
                    case "SCREENSHOTS":
                        return "Circalind-Seiten";
                    case "PROTOCOLS":
                        return "Protokolle";
                    case "BRAINSTORMING":
                        return "Brain-Storming";
                }
            })();

            return [...Array(columns).keys()].map(column => {
                return {
                    row: row,
                    column: column,
                    height: 1,
                    width: 1,
                    type: column === 0 ? "INFO" : "MULTIFILE",
                    id: (row + 1) * 10 + column + 1,
                    value: column === 0 ? rowTitle : JSON.stringify(getItemsForPhaseAndType(projectSpecific ? column : column - 1, type)),
                    placeholder: column === 0 ? rowTitle : "file",
                    style: null
                }
            });
        }

        const generateHeaderRow = (projectSpecific: boolean): Array<TableCell> => {
            const columns = projectSpecific ? ((license && (["UK1", "PK4"] as Array<LicenseTypes>).includes(license.type)) ? 1 : 6) : 5;


            return [...Array(columns).keys()].map(column => {
                const placeholder = projectSpecific ?
                    (column === 0 ? "Projekt: " + (project ? project.name : " - ") : `${column}`) :
                    (column > 0 ? `${managementPhases.flat()[column - 1]}` : "");
                return {
                    row: 0,
                    column: column,
                    height: 1,
                    width: 1,
                    type: column === 0 || !projectSpecific ? "INFO" : "PHASEICON",
                    id: 10 + column + 1,
                    value: projectSpecific ? (column === 0 ? "" : `${column}`) : "",
                    placeholder: placeholder,
                    style: null
                }
            });
        }

        const headerRow = generateHeaderRow(projectSpecific);
        const contentRowTypes: Array<DeskType> = projectSpecific ? ["SCREENSHOTS", "DOCUMENTS", "QUOTE", "PHOTOS", "PRESENTATION"] : ["SCREENSHOTS", "DOCUMENTS", "BRAINSTORMING", "PROTOCOLS", "PRESENTATION"];

        return {
            tableCells: [...headerRow, ...contentRowTypes.map((ct, index) => generateContentRow(index + 1, ct)).flat()],
            cols: projectSpecific ? ((license && (["UK1", "PK4"] as Array<LicenseTypes>).includes(license.type)) ? 2 : 6) : 5,
            rows: 6,
            title: "Information",
            style: null
        };
    };

    const downloadAllLink = projectSpecific ? `${CircalindApi.serverURL}desk/all/project/download?project=${projectId}&token=${getToken("token-circalind")}` : `${CircalindApi.serverURL}desk/all/group/download?group=${groupId}&token=${getToken("token-circalind")}`

    const updateElement = (item: DeskItem) => {
        setSelectedItem(item);
        setItems([...items.filter(i => i.id !== item.id), item]);
    };

    const updateProjectDocumentation = async (documentation: boolean) => {
        if (project) {
            const newProject = { ...project, documentation };
            const res = await CircalindApi.addNewProject(newProject);

            if (res) {
                setProject(newProject);
                setProjects([...projects.filter(p => p.id !== project.id), newProject]);
            }
        }
    };

    const canCheckProjectDocumentation = () => {
        if (license && project) {
            if (license.type === "UK2") {
                if (project.documentation) {
                    return true;
                } else {
                    if (project.compact) {
                        return projects.filter(p => p.compact && p.documentation).length < 5;
                    } else {
                        return projects.filter(p => !p.compact && p.documentation).length < 2;
                    }
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    return <div className="project-desk">
        {(projectSpecific && license && license.type !== "UK1") ? <PhaseHeader current={projectId} /> : undefined}
        <div className="content-wrapper">
            <div className="search-bar">
                <div className="actions">
                    <img className="corner-img" src={deskImage} alt="" />
                    <button disabled={projectSpecific && projectId === -1}>
                        <a rel="noreferrer" href={downloadAllLink} target="_blank"><FontAwesomeIcon icon={faDownload} /></a>
                    </button>
                    <button disabled={projectSpecific && projectId === -1}>
                        <FontAwesomeIcon icon={faTrashAlt} onClick={async () => {
                            if (window.confirm("Wirklich alle Elemente löschen?")) {
                                if (projectSpecific ? await CircalindApi.deleteAllDeskItemsForProject(projectId) : await CircalindApi.deleteAllDeskItemsForGroup(groupId)) {
                                    sendOlert("Erfolg", "Löschen war erfolgreich.");
                                    updateItems();
                                }
                            }
                        }} />
                    </button>
                    {projectSpecific && <button disabled={!projectSpecific || (projectSpecific && projectId === -1)} onClick={() => setShowShareProjectDialog(true)}>
                        <FontAwesomeIcon icon={faShare} />
                    </button>}
                    {projectSpecific && <button className="no-padding" onClick={() => navigate("/circalind/regular/dashboard/base/start")}><img src={EmptyProjectImage} alt="Basis" /></button>}
                </div>
                <TagList tags={searchTags} onChange={setSearchTags} />
            </div>
            <CompassTable
                answers={[]}
                data={generateData()}
                edit={false}
                onCellAnswerd={onAnswer}
                onUpdate={onUpdate}
                onCellItemClicked={onItemClicked}
            />
            {license && project && (["UK2", "UK3"] as Array<LicenseTypes>).includes(license.type) && projectSpecific && <div className="way">
                <div>
                    {project && <span><input id="projectDocumentationUpdate" onChange={(e) => updateProjectDocumentation(e.target.checked)} disabled={!canCheckProjectDocumentation()} checked={project.documentation} type="checkbox" /><label htmlFor="projectDocumentationUpdate">Freischalten</label></span>}
                </div>
                <button disabled={!project.documentation} onClick={() => navigate("waybooklet")}>
                    <img src={mywayImage} alt="Mein-Weg" /> {project.compact ? "Projekt-Weg" : "Mein-Weg"}
                </button>
            </div>}
        </div>
        <Dialog className="detailProject" show={newDialog} toogle={toggleDialog} component={<NewDeskItem doUpdate={updateItems} onclose={toggleDialog} phase={phase} phaseName={projectSpecific ? `${phase}` : managementPhases.flat()[phase]} projectId={projectSpecific ? projectId : -1} groupId={projectSpecific ? -1 : groupId} type={type} />} />
        <Dialog className="" show={showShareProjectDialog} toogle={() => setShowShareProjectDialog(false)} component={<ShareProjectDialog closeDialog={() => setShowShareProjectDialog(false)} />} />
        {selectedItem && <Dialog className="" show={showTagDialog} toogle={() => setShowTagDialog(false)} component={<TagDialog selectedItem={selectedItem} updateSelectedItems={updateElement} closeDialog={() => setShowTagDialog(false)} />} />}
    </div>

}

export const ShareProjectDialog = ({ closeDialog }: { closeDialog: () => void }) => {
    const [projectId,] = useSelectedProject();
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [selectedProjectId, setSelectedProjectId] = useState<number>();

    useEffect(() => {
        const loadProjects = async () => {
            setProjects(await CircalindApi.getProjects());
        };

        loadProjects();
    }, []);

    const saveProject = async () => {
        if (projectId !== -1 && selectedProjectId) {
            const result = await CircalindApi.linkChildProject(selectedProjectId, projectId);

            if (result) {
                sendOlert("Erfolg", "Hinzufügen des Projekts zur Historie war erfolgreich.", "Success");
                closeDialog();
            } else {
                sendOlert("Erfolg", "Fehler beim Hinzufügen des Projekts zur Historie.", "Error");
            }
        }
    };

    return <div className="share-project-dialog">
        <h2>Zur Projekthistorie hinzufügen</h2>
        <h3>Wählen Sie ein Projekt aus</h3>
        <ProjectSelect valueProjectId={selectedProjectId} projects={projects} onSelect={p => setSelectedProjectId(p?.id)} />
        <div className="button-container">
            <button disabled={projectId === -1 || selectedProjectId === undefined} onClick={saveProject}>Hinzufügen</button>
        </div>
    </div>;
};

export const TagList = ({ tags, onChange }: { tags: Array<string>, onChange: (tags: Array<string>) => void }) => {
    const [tag, setTag] = useState<string>("");
    const addTag = (tag: string) => {
        if (tag) {
            onChange([...tags, tag]);
            setTag("");
        }
    };

    return <div className="tag-list">

        {tags.map((t, index) => {
            const deleteTag = () => {
                const newTags = [...tags];
                newTags.splice(index, 1);
                onChange(newTags);
            };
            return <span className="tag" key={index}><span>{t}</span><span><FontAwesomeIcon className="clickable" icon={faTrashAlt} onClick={deleteTag} /></span></span>
        })}
        <input placeholder="Stichworte ..." value={tag} onChange={e => setTag(e.target.value)} onKeyUp={(event) => {
            if (event.key === "Enter") {
                addTag(tag)
            }
        }}></input>
        <button onClick={() => addTag(tag)}>Hinzufügen</button>
    </div>
};

const TagDialog = ({ selectedItem, updateSelectedItems, closeDialog }: { selectedItem: DeskItem, updateSelectedItems: (item: DeskItem) => void, closeDialog: () => void }) => {
    const saveTags = async () => {
        if (await CircalindApi.updateTagsForDeskItem({ itemId: selectedItem.id, tags: selectedItem.tags })) {
            sendOlert("Erfolg", "Stichworte wurden aktualisiert.", "Success");
            closeDialog();
        } else {
            sendOlert("Fehler", "Fehler beim Aktualisieren der Stichworte", "Error");
        }
    };

    return <div className="tag-dialog">
        <TagList tags={selectedItem.tags} onChange={(tags) => updateSelectedItems({ ...selectedItem, tags })} />
        <button onClick={saveTags}>Speichern</button>
    </div>
};